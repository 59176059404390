import React from "react";
import LinkedInIcon from "../images/icons/linkedin.svg";
import FacebookIcon from "../images/icons/facebook.svg";
import TwitterIcon from "../images/icons/twitter.svg";
import Fade from "react-reveal/Fade";

const BlogPostContent = ({ BlogContent, BlogHeader }) => {
  return (
    <section className="blog--post--content">
      {BlogContent}

      <Fade duration={2000}>
        <div className="blog--post--share">
          <h5>Share this post</h5>
          <a
            href={
              "https://www.linkedin.com/sharing/share-offsite/?url=http://www.datakraken.net/" +
              BlogHeader.url
            }
            target="_blank"
            rel="noreferrer"
          >
            <img src={LinkedInIcon} alt="social-icon"></img>
          </a>
          <a
            href={
              "https://www.facebook.com/sharer/sharer.php?u=#http://www.datakraken.net/" +
              BlogHeader.url
            }
            target="_blank"
            rel="noreferrer"
          >
            <img src={FacebookIcon} alt="social-icon"></img>
          </a>
          <a
            href={
              "http://www.twitter.com/share?url=http://www.datakraken.net/" +
              BlogHeader.url
            }
            target="_blank"
            rel="noreferrer"
          >
            <img src={TwitterIcon} alt="social-icon"></img>
          </a>
        </div>
      </Fade>
    </section>
  );
};

export default BlogPostContent;
