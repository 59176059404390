import React, { useContext } from "react";
import BlogPostPreview from "./../components/BlogPostPreview";
import Fade from "react-reveal/Fade";

import { BlogListContext } from "../pages/blog/BlogList";

const HomepageBlog = () => {
  const [blogList] = useContext(BlogListContext);

  return (
    <section className="homepage--blog">
      <div className="container">
        <Fade duration={2000}>
          <h1 className="text-center homepage--blog--header">
            Explore our latest <br />
            <span className="colour--red">news & insights</span>
          </h1>
        </Fade>
        <hr className="dk--divider" />

        <div className="row pt-5">
          {blogList.slice(0, 4).map((post) => (
            <Fade duration={2000} key={post.id}>
              <div className="col-12 col-lg-6">
                <BlogPostPreview
                  Title={post.title}
                  Date={post.date}
                  Author={post.author}
                  Banner={post.banner}
                  Snippet={post.snippet}
                  URL={'blog/' + post.url}
                />
              </div>
            </Fade>
          ))}
        </div>

        <div className="row py-5 text-center">
          <Fade duration={2000}>
            <a href="/blog" className="homepage--blog--all">
              <h5 className="text-center">View All</h5>
            </a>
          </Fade>
        </div>
      </div>
    </section>
  );
};

export default HomepageBlog;
