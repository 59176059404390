import React from "react";
import Hero from "../layouts/Hero";
import HeroBG from "../images/data_collection_hero.svg";
import DataCollectionIcon from "../images/icons/data_collection.svg";
import WorkNextPreview from "../layouts/WorkNextPreview";
import Tentacles from "../images/tentacles-service-page.svg";
import Fade from "react-reveal/Fade";
import MetaTags from "react-meta-tags";

const ServicesDataCollection = () => {
  return (
    <section className="services--page services--collection">
      <MetaTags>
        <title>Services - Data Collection - Data Kraken</title>
        <meta
          name="description"
          content="Accurate Data Collection is the first step in creating a Data-Driven Business.  As the adage goes, ‘Garbage In, Garbage Out’. Here at Data Kraken we understand the necessity of a robust Data Collection system and we can help build this. "
        />
      </MetaTags>
      <Hero
        heading="Data Collection"
        subheading="Accurate Data Collection is the first step in creating a Data-Driven Business.  As the adage goes, ‘Garbage In, Garbage Out’. Here at Data Kraken we understand the necessity of a robust Data Collection system and we can help build this. "
        icon={HeroBG}
        icon_width="40vw"
        icon_padding="0% 3%"
        small_icon={DataCollectionIcon}
      />

      <Fade duration={2000}>
        <section className="services--page--info">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-6">
                <div className="row pb-5">
                  <h2>Data Migration</h2>
                  <p>
                    If the data in your business is sitting across many
                    different systems and in many different formats, it can be
                    difficult to get a 360-view of your customers. We can help
                    pull your data from a multitude of different sources
                    including, but not limited to Databases, MS Excel, Email &
                    Websites. These can then be combined into one cohesive
                    centralised data warehouse for use across all areas of your
                    business.
                    <br />
                    <br />
                    This reduces complexity, allows scalability and
                    auditability, as well as giving you the ability to unlock
                    insight about your business. Say goodbye to those huge MS
                    Excel spreadsheets with millions for formulas that take 20
                    minutes to open!
                  </p>
                </div>
                <div className="row pb-5">
                  <h2>Automated Imports</h2>
                  <p>
                    To ensure robust Insight, Campaigns and Reporting, your
                    business need to ensure the data you receive comes in
                    accurately and with a minimum amount of fuss. Here at Data
                    Kraken, we have our own automated import software to ensure
                    the Extract, Transform and Load (ETL) part of your data
                    solution is as accurate as possible. <br />
                    <br /> There will always be problems with data ever now and
                    then but our solution ensures yours (or our) Operations Team
                    will be notified about any unexpected issue as soon as they
                    occur.
                  </p>
                </div>
                <div className="row pb-5">
                  <h2>Big Data</h2>
                  <p>
                    Being able to crunch huge volumes of data as quick as
                    possible is now a necessity for so many companies to operate
                    in the fast-paced world we now live in. The expectation from
                    consumers today is that they want to be served real-time
                    with minimal fuss. A big data solution will allow that to
                    happen as you’ll be able to make real-time decisions for
                    customers while they are interacting with you.
                  </p>
                </div>
                <div className="row pb-5">
                  <h2>API Integration</h2>
                  <p>
                    In our highly connected world, API Integrations are critical
                    for use in all businesses. Whether you need to have API
                    systems built or want to send/receive data via APIs, our
                    experts will create seamless integrations to and from your
                    business.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <img src={Tentacles} alt="" className="services--page--tentacles" />
        </section>
      </Fade>

      <Fade duration={2000}>
        <WorkNextPreview
          heading="View examples of our work"
          preview1="O2 Wifi"
          preview2="48 Ireland"
        />
      </Fade>
    </section>
  );
};

export default ServicesDataCollection;
