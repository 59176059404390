import React from "react";
import Hero from "../layouts/Hero";
import WorkListPreview from "../layouts/WorkListPreview";
import Testimonials from "../layouts/Testimonials";
import HeroBG from "../images/work_hero.svg";

import MetaTags from "react-meta-tags";

const Work = () => {
  return (
    <section className="work">
      <MetaTags>
        <title>Work - Data Kraken</title>
        <meta name="description" content="Explore some of our past work" />
      </MetaTags>
      <Hero
        heading="Explore some of our past work"
        subheading={`Take a look at some of our case studies for past and current projects. As well as some testimonials from our clients...`}
        icon={HeroBG}
        icon_width="40vw"
        icon_padding="0% 4%"
      />

      <WorkListPreview />
      <Testimonials />
    </section>
  );
};

export default Work;
