import React from "react";
import Hero from "../layouts/ContactHero";
import ContactLinks from "../layouts/ContactLinks";
import ContactMap from "../layouts/ContactMap";
import MetaTags from "react-meta-tags";

const Contact = () => {
  return (
    <section className="contact">
      <MetaTags>
        <title>Contact - Data Kraken</title>
        <meta name="description" content="We’re always happy to talk." />
      </MetaTags>

      <Hero />
      <ContactLinks />
      <ContactMap />
    </section>
  );
};

export default Contact;
