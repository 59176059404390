import React from "react";

import predictive_moddeling from "../../images/blog/why_churn_prediction_aint_churn_prevention/predictive-modelling.png";
import true_negative from "../../images/blog/why_churn_prediction_aint_churn_prevention/true-negative.png";
import true_positive from "../../images/blog/why_churn_prediction_aint_churn_prevention/true-positive.png";
import false_negative from "../../images/blog/why_churn_prediction_aint_churn_prevention/false-negative.png";
import false_positive from "../../images/blog/why_churn_prediction_aint_churn_prevention/false-positive.png";

import tom1 from "../../images/blog/why_churn_prediction_aint_churn_prevention/tom1.png";
import tom2 from "../../images/blog/why_churn_prediction_aint_churn_prevention/tom2.png";
import tom3 from "../../images/blog/why_churn_prediction_aint_churn_prevention/tom3.png";
import tom4 from "../../images/blog/why_churn_prediction_aint_churn_prevention/tom4.png";

import Fade from "react-reveal/Fade";

const Why_churn_prediction_aint_churn_prevention = () => {
  // Write blog content here
  return (
    <div>
      <Fade duration={1000}>
        <p>
          As a self-confessed stats geek and CEO of an analytical consultancy, I
          should probably be the first person to try to sell you the benefits of
          churn prediction models.
        </p>

        <p>
          Just so we’re all clear, the churn prediction I’m talking about isn’t
          the likelihood of milk turning into butter - that will perhaps be in a
          future blog. The churn prediction I’m talking about is the one about
          customers leaving your business. The Internet describes it as, ‘
          <b>churn prediction modelling</b> techniques attempt to understand the
          precise customer behaviours and attributes which signal the risk and
          timing of customer <b>churn</b>.'
        </p>

        <p>
          Basically, it’s about predicting customers leaving before they’ve
          left. The theory is that if you can identify them before they leave,
          you can act on that information, tell them how much you love them, and
          then they’ll stay. If you think you’ve heard of a similar concept
          before, you have - it’s a central theme in Steven Spielberg’s 2002
          film,{" "}
          <a
            href="https://www.imdb.com/title/tt0181689/"
            target="_blank"
            rel="noreferrer"
          >
            Minority Report
          </a>{" "}
          (based on the 1956 short story "
          <a
            href="https://en.wikipedia.org/wiki/The_Minority_Report"
            target="_blank"
            rel="noreferrer"
          >
            The Minority Report
          </a>
          " by{" "}
          <a
            href="https://en.wikipedia.org/wiki/Philip_K._Dick"
            target="_blank"
            rel="noreferrer"
          >
            Philip K. Dick
          </a>
          )
        </p>

        <p>
          The film stars Mr runs-around-a-lot Tom Cruise as a police officer
          where{" "}
          <a
            href="https://en.wikipedia.org/wiki/Precrime"
            target="_blank"
            rel="noreferrer"
          >
            PreCrime
          </a>
          , a specialized police department, apprehends criminals based on
          <a
            href="https://en.wiktionary.org/wiki/foreknowledge"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            foreknowledge
          </a>{" "}
          provided by three psychics called "{" "}
          <a
            href="https://en.wikipedia.org/wiki/Precognition"
            target="_blank"
            rel="noreferrer"
          >
            precogs
          </a>
          ".{" "}
        </p>
        <p>
          I’ll not spoil it for you, but things didn’t go according to plan as
          sometimes predictions can go wrong.
        </p>

        <p>
          From a statistical point of view, predictive modelling can be split
          into 4 different sections, which are laid out in the chart below with
          Minority Report as an example:
        </p>

        <img
          src={predictive_moddeling}
          className="w-100 p-5"
          alt="predictive-modelling"
        ></img>
        <p>
          Let's look at each of these individually and explain what they mean…
        </p>
        <img
          src={true_negative}
          className="w-100 py-5"
          alt="true-negative"
        ></img>

        <div className="row">
          <div className="col-12 col-lg-6 order-last order-lg-first">
            <p>
              This makes Tom Cruise and statistical modellers happy. Tom gets to
              lock away bad guys before they get to commit a crime and
              statistical modellers get to prove that their models are accurate
              by correctly predicting people who are about to leave. Tom heads
              to the pub with his friends at{" "}
              <a
                href="https://en.wikipedia.org/wiki/Precrime"
                target="_blank"
                rel="noreferrer"
              >
                PreCrime
              </a>{" "}
              and the data modeller goes home and plays with their Lego
              Millennium Falcon.
            </p>
          </div>

          <div className="col-12 col-lg-6 ">
            <img src={tom1} className="w-100" alt="true-negative"></img>
            <p className="text-center pt-3">Happy bunny Mr C</p>
          </div>
        </div>

        <img
          src={true_positive}
          className="w-100 py-5"
          alt="true-positive"
        ></img>

        <div className="row">
          <div className="col-12 col-lg-6">
            <img src={tom2} className="w-100" alt="true-negative"></img>
            <p className="text-center pt-3">Ambivalent TC</p>
          </div>

          <div className="col-12 col-lg-6">
            <p>
              This is the picture you get when you type ‘Tom Cruise ambivalent’
              in Google, which describes how he and statistical modellers feel
              about ‘True Positives’. In Minority Report, the{" "}
              <a
                href="https://en.wikipedia.org/wiki/Precognition"
                target="_blank"
                rel="noreferrer"
              >
                precogs
              </a>{" "}
              do not care about innocent people being innocent. In the same ilk,
              statistical modellers or retention managers don’t care about
              existing customers who are staying (even if the Marketing
              department do).
            </p>
          </div>
        </div>

        <img
          src={false_positive}
          className="w-100 py-5"
          alt="true-negative"
        ></img>

        <div className="row">
          <div className="col-12 col-lg-6 order-last order-lg-first">
            <p>
              Tom is quite mournful with Type I Errors. This is where he finds a
              person guilty who is actually innocent. There have been a lot of
              famous Type I Errors throughout history, so I’ll just leave this
              here…{" "}
              <a
                href="https://en.wikipedia.org/wiki/List_of_exonerated_death_row_inmates"
                target="_blank"
                rel="noreferrer"
              >
                List of exonerated death row inmates
              </a>
            </p>

            <p>
              From a Statistical Modeller's point of view, these are customers
              who are incorrectly identified as leaving. This is an unfortunate
              truth of model building - that no model will ever be perfect. For
              a company, this means you may be wasting valuable resources on
              trying to retain customers which were anyway going to stay, which
              can be costly. This could be improved through creating a better
              model or gathering more data on your customers.
            </p>
          </div>

          <div className="col-12 col-lg-6">
            <img src={tom3} className="w-100" alt="true-negative"></img>
            <p className="text-center pt-3">Pensive Tommy</p>
          </div>
        </div>

        <img
          src={false_negative}
          className="w-100 py-5"
          alt="false-negative"
        ></img>

        <div className="row">
          <div className="col-12 col-lg-6">
            <img src={tom4} className="w-100" alt="true-negative"></img>
            <p className="text-center pt-3">Angry Thomas</p>
          </div>

          <div className="col-12 col-lg-6">
            <p>
              Tom isn’t a happy bunny, statistical modeller isn’t a happy bunny
              and the retention manager DEFINITELY isn’t a happy bunny. Tom
              knows a guilty person has got away, the statistical modeller’s
              model didn’t predict someone leaving and the retention manager’s
              Customer Base has gone down as they’ve lost a customer. Unhappy
              times all round.
            </p>
          </div>
        </div>

        <p className="pt-5">
          Obviously, Type I and Type II errors are bad and one solution to
          reduce them is through small adjustments to the model, or gathering
          more data on the customers. However, even with a 100% accurate churn
          prediction model, that isn’t going to keep customers who were
          intending to leave. Ultimately, the overall business goal is churn
          prevention (i.e. preventing customers from leaving), not just creating
          a great statistical model after all.
        </p>

        <h3>Let’s talk churn prevention</h3>

        <p>
          If you have a super-duper Churn Prediction model that will correctly
          predict if a customer will leave or not, that is only half the battle.
          If your Statistical Modeller creates something that lets you know a
          customer is definitely going to leave in the next 28 days, what do you
          do?
        </p>

        <p>
          <ol>
            <li>
              Call them and beg them to stay with a new contract at a better
              rate?
            </li>
            <li>
              Give them 3 months free or half price to lock them in for a bit
              longer?
            </li>
            <li>Give them free vouchers from Just Eat or Amazon?</li>
            <li>
              Send them some flowers asking them that you’ll change and try
              harder from now on?
            </li>
          </ol>
        </p>

        <p>
          I could go on, but can you see the point? There are lots of different
          churn prevention tools, but which is the right one, and more
          specifically, how am I supposed to know?{" "}
        </p>

        <p>
          {" "}
          <a
            href="https://en.wikipedia.org/wiki/Gamble_and_Huff"
            target="_blank"
            rel="noreferrer"
          >
            Gamble and Huff
          </a>{" "}
          who wrote a song in the 1950s called{" "}
          <a
            href="https://youtu.be/UxOZ6gifTjA"
            target="_blank"
            rel="noreferrer"
          >
            ‘If you don’t know me by now'
          </a>{" "}
          If You Don't Know Me By Now - Harold Melvin. You may think it was
          written about a relationship between a man and a women, but I choose
          to believe it was about a disgruntled customer and a service contract
          provider.{" "}
        </p>

        <p>
          Ultimately, if you are worried about your customers leaving and you
          don’t know what to do about it, it’s a bit too late I’m afraid.
          Successful businesses aim to know their customers from day one, and
          nurture that relationship from the start. That relationship should be
          treated like any other relationship, whether it’s with a partner,
          friend, pet or plant, i.e. learn about what they like and what they
          don’t like and, whatever you do, don’t take them for granted.{" "}
        </p>

        <p>
          The biggest predictor I’ve consistently seen in churn prediction model
          building is users' answers to the question ‘Why are you joining us?’.
          From my 22 years of working with Churn Prediction models, this is THE
          Golden Question to ask during the onboarding process. If you know why
          people are joining, you’ll be able to determine why they may want to
          leave in the future and what you can do about it.{" "}
        </p>

        <p>
          Then, after a customer has joined, learning as much as possible about
          them will help you shape their relationship with you. If you do
          everything right, they won’t want to leave and hence Churn Prediction
          isn’t required. The big caveat here is ‘do everything right’, this is
          a difficult task but understanding your customers through usage
          segmentation, demographic profiling and relevant & timely CRM{" "}
          <a
            href="https://en.wikipedia.org/wiki/Customer_relationship_management"
            target="_blank"
            rel="noreferrer"
          >
            Customer relationship management
          </a>{" "}
          is key.
        </p>

        <p>
          Ultimately, if you are worried about your customers leaving and you
          don’t know what to do about it, it may be too late for your existing
          customers - but never too late to start for your new customers.
        </p>

        <p>
          However, to have effective churn prevention you need to have robust
          and accurate data at the heart of your business….and you know who can
          help you create this, don’t you? (Hint: It’s not Tom Cruise!)
        </p>
      </Fade>
    </div>
  );
};

export default Why_churn_prediction_aint_churn_prevention;
