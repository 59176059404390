import React from "react";
import Hero from "../layouts/Hero";
import HeroBG from "../images/data_management_hero.svg";
import DataManagementIcon from "../images/icons/data_management.svg";
import WorkNextPreview from "../layouts/WorkNextPreview";
import Tentacles from "../images/tentacles-service-page.svg";
import Fade from "react-reveal/Fade";
import MetaTags from "react-meta-tags";

const ServicesDataManagement = () => {
  return (
    <section className="services--page services--management">
      <MetaTags>
        <title>Services - Data Management - Data Kraken</title>
        <meta
          name="description"
          content="Our team are able manage your data to ensure that it is secure, efficient, and cost-effective. This will give you the peace of mind that your Data System is looked after by experts."
        />
      </MetaTags>
      <Hero
        heading="Data Management"
        subheading="Our team are able manage your data to ensure that it is secure, efficient, and cost-effective. This will give you the peace of mind that your Data System is looked after by experts."
        icon={HeroBG}
        icon_width="40vw"
        icon_padding="0% 3%"
        small_icon={DataManagementIcon}
      />
      <Fade duration={2000}>
        <section className="services--page--info">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-6">
                <div className="row pb-5">
                  <h2>Operational Support</h2>
                  <p>
                    If you have hundreds of data feeds, reports, statistical
                    models and campaigns being generated daily, it can be quite
                    overwhelming when there are issues. Our Operational Support
                    Team can remove this stress from your business and manage
                    this on your behalf. We will have alerts to be able identify
                    issues quickly and our team of dedicated experts will look
                    into the issue even if it’s with one of your 3rd parties.
                  </p>
                </div>
                <div className="row pb-5">
                  <h2>Cloud Hosting</h2>
                  <p>
                    We take advantage of distributed systems within our cloud
                    hosting suppliers which provide security, reliability and
                    scalability for anything that needs to be hosted online. The
                    other advantage of Cloud Hosting is that it is also very
                    good value for money.
                  </p>
                </div>
                <div className="row pb-5">
                  <h2>GDPR Compliance/Security (ISO27001)</h2>
                  <p>
                    The General Data Protection Regulation (GDPR) is a legal
                    framework that sets guidelines for the collection and
                    processing of personal information from individuals who live
                    in the European Union (EU). Since 2016, companies in the EU
                    have to follow seven key principles around data or risk
                    large fines. The principles are as follows:
                  </p>

                  <p>
                    <ul>
                      <li>Lawfulness, fairness and transparency</li>
                      <li>Purpose limitation</li>
                      <li>Accuracy</li>
                      <li>Data Minimisation</li>
                      <li>Storage Limitation</li>
                      <li>Integrity and confidentiality (security)</li>
                      <li>Accountability</li>
                    </ul>
                  </p>

                  <p>
                    We at Data Kraken can help with creating a Data Strategy to
                    make sure your business meets all of these principles to
                    ensure your business is compliant. The days of storing and
                    managing data irresponsibly are over. Google, H&M and
                    British Airways are just a few companies that have been
                    fined over €20 million for violations.
                  </p>

                  <p>
                    Here at Data Kraken we follow ISO 27001 security guidance.
                    This means that we know exactly what Information Security is
                    necessary for your business. We implement only the Security
                    controls you really need and take Security extremely
                    seriously.
                  </p>
                </div>
                <div className="row pb-5">
                  <h2>Patching/Optimisation</h2>
                  <p>
                    As your business grows, the amount of data you’ll need will
                    increase which can have performance impacts. Our DevOps Team
                    will identify parts of your Data System that are suboptimal
                    and strive to make them more efficient. We will continually
                    carry out Database and Server-level optimisation to ensure
                    that your services are running as effectively as possible.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <img src={Tentacles} alt="" className="services--page--tentacles" />
        </section>
      </Fade>
      <Fade duration={2000}>
        <WorkNextPreview
          heading="View examples of our work"
          preview1="Tuenti"
          preview2="Insure and go"
        />
      </Fade>
      d
    </section>
  );
};

export default ServicesDataManagement;
