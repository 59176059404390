import React from "react";
import WorkHero from "../../layouts/WorkHero";
import InsureAndGoLogo from "../../images/work/insure-and-go/insure-and-go-logo.png";
import InsureAndGoBanner from "../../images/work/insure-and-go/insure-and-go-banner2.jpg";
import WorkListSummaryPoints from "../../components/WorkPostSummaryPoints";

import WorkNextPreview from "../../layouts/WorkNextPreview";
import MetaTags from "react-meta-tags";

import Fade from "react-reveal/Fade";

const InsureAndGo = () => {
  return (
    <section className="work--post work--insure--and--go">
      <MetaTags>
        <title>Insure And Go - Work - Data Kraken</title>
        <meta
          name="description"
          content="Insure and Go tasked Data Kraken with architecting and project managing a strategic data warehouse which would sit at the heart of the business for all directorates."
        />
      </MetaTags>

      <WorkHero
        Logo={InsureAndGoLogo}
        Overview="Insure and Go tasked Data Kraken with architecting and project managing a strategic data warehouse which would sit at the heart of the business for all directorates."
        Banner={InsureAndGoBanner}
      />

      <div className="container">
        <Fade duration={2000}>
          <div className="row">
            <div className="col-12 col-lg-3 order-sm-first">
              <WorkListSummaryPoints
                Client="Insure and Go"
                Industry="Travel Insurance"
                Service="Data Management"
                Location="United Kingdom"
              />
            </div>

            <div className="col-12 col-lg-8">
              <h1>About Insure and Go</h1>
              <p>
                A specialist travel insurance provider, providing insurance for
                more niche holidays and activities.
              </p>

              <h1 className="pt-5">Challenge</h1>
              <p>
                Insure and Go tasked Data Kraken with architecting and project
                managing a strategic data warehouse which would sit at the heart
                of the business for all directorates.
              </p>
              <p>
                This is quite common in many businesses where{" "}
                <b>
                  <a
                    href="https://searchdatamanagement.techtarget.com/definition/data-silo"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <u>Data Silos</u>
                  </a>
                </b>{" "}
                can occur. Data Silos manifest when different organisational
                units store and manage data specifically relevant to their
                function. This can lead to two main problems:
              </p>
              <p>
                1. Multiple definitions and calculations across each department
              </p>

              <p>
                2. Duplication of work and resource; each silo needs their own
                version of a Database Administrator/MS Excel Guru. (As much as
                we think MS Excel is a useful tool; we’ve seen a majority of
                businesses that have Data Silos also have MS Excel pulling the
                strings in centre of the Silo)
              </p>

              <h1 className="pt-5">Our Work</h1>
              <p>
                In a highly competitive market, Insure and Go needed to look at
                how to get a competitive advantage by making their decisions
                even quicker and even more data-driven. This work required
                understanding and documenting all data output requirements from
                all organisational units. For most large organisations these
                would include outputs such as: sales reports, regulatory
                reports, operational dashboards, CRM campaigns, etc.
              </p>

              <p>
                Building a centralised data function isn’t just about having{" "}
                <b>
                  <span className="colour--red">one version of the truth</span>
                </b>{" "}
                but also{" "}
                <b>
                  <span className="colour--red">
                    one version of definitions
                  </span>
                </b>{" "}
                i.e. a global business glossary. It’s not uncommon to have
                different definitions for very simple concepts like a customer.
                What is a customer could be the bill payer, the product consumer
                or the orderer. All of which need defining and understood by all
                areas of the business. Once the business glossary and
                requirements are understood, building a centralised data
                repository becomes an achievable task.
              </p>

              <p>
                Like many businesses, Insure and Go had multiple systems looking
                at processes through a different lens. We helped creating a{" "}
                <b>
                  <span className="colour--red">golden source</span>
                </b>{" "}
                for all data feeds as well as creating logic for creating
                complex reporting outputs such as channel commission reporting
                within their relational data warehouse.
              </p>
            </div>
          </div>
        </Fade>

        <Fade duration={2000}>
          <div className="row py-5">
            <blockquote className="blockquote">
              <div className="blockquote--dash">
                Data Kraken has helped us to architect , build and manage the
                creation of a data warehouse. Data Kraken has a wealth of
                experience managing data needs for different industries. <br />
                <br /> From day one, Gope understood our strategy , needs and
                context. He was fully involved in helping us to get over the
                line, by supporting and mentoring the team and working with the
                stakeholders in the company, helping us achieve our goals.
                <br />
                <br />— Ana Vilda, Insure and Go CIO
              </div>
            </blockquote>
          </div>
        </Fade>
      </div>

      <WorkNextPreview
        heading="Up next..."
        preview1="Reath"
        preview2="Tuenti"
      />
    </section>
  );
};

export default InsureAndGo;
