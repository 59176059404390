import React, { useContext } from "react";
import { WorkListContext } from "../pages/work/WorkList";

import Fade from "react-reveal/Fade";
import WorkPostPreview from "../components/WorkPostPreview";

const WorkNextPreview = ({ heading, preview1, preview2 }) => {
  const [workList] = useContext(WorkListContext);

  let post1 = workList.filter((post) => post.title === preview1)[0];
  let post2 = workList.filter((post2) => post2.title === preview2)[0];

  return (
    <section className="work--next--preview">
      <div className="container">
        <h1>{heading}</h1>
        <div className="row">
          <Fade duration={2000}>
            <div className="col-12 col-lg-6 p-5">
              <WorkPostPreview
                Title={post1.title}
                Subtitle={post1.subtitle}
                Banner={post1.banner}
                URL={post1.url}
              />
            </div>
            <div className="col-12 col-lg-6 p-5">
              <WorkPostPreview
                Title={post2.title}
                Subtitle={post2.subtitle}
                Banner={post2.banner}
                URL={post2.url}
              />
            </div>
          </Fade>

          <a href="/work" className="work--next__all">
            <h5 className="text-center">View All</h5>
          </a>
        </div>
      </div>
    </section>
  );
};

export default WorkNextPreview;
