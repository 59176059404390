import React from "react";
import Fade from "react-reveal/Fade";

import ImgPicture1 from "../../images/blog/whats_it_like_working_for_an_IT_company_located_in_another_region_of_the_world/picture1.png";
import ImgPicture2 from "../../images/blog/whats_it_like_working_for_an_IT_company_located_in_another_region_of_the_world/picture2.png";
import ImgPicture3 from "../../images/blog/whats_it_like_working_for_an_IT_company_located_in_another_region_of_the_world/picture3.png";
import ImgPicture4 from "../../images/blog/whats_it_like_working_for_an_IT_company_located_in_another_region_of_the_world/picture4.png";

const Whats_it_like_working_for_an_IT_company_located_in_another_region_of_the_world =
  () => {
    // Write blog content here
    return (
      <div>
        <Fade duration={1000}>
          <p>
            <b>
              <i>
                Introducing Hirosha, based in Sri Lanka and in his 6th year of
                working with Data Kraken.
              </i>
            </b>
          </p>

          <p>
            As we find ourselves more than 12 months into a pandemic, a question
            we’re all starting to ask ourselves is ‘Do I like working in an
            office, or would I rather be working at home?’ Depending on your
            job, you’ve most probably had to work in an office in the past, as
            there was often no alternative unless you were self-employed. Back
            in the office itself, we’re all familiar with its pros and cons -
            you might have found it a distracting environment, with silent wars
            over the temperature and waiting for the microwave. On the other
            hand, you might have enjoyed the entertainment, team bonding and
            social aspect that is so unique to the office.
          </p>

          <img src={ImgPicture1} alt="img-1" className="w-100 py-5" />
          <p>
            <b>
              This isn’t an easy choice when you’re based in Sri Lanka and the
              office is in the UK…
            </b>
          </p>

          <p>
            So, when based 6000 miles away from where you work, how do you make
            it work? And what are the complications of working for a foreign
            company? There are a few solutions, such as basing yourself in near
            the company for a few months at a time and coming back home for few
            weeks. However, the easiest and most environmentally friendly way is
            working remotely, keeping in touch via video call, email, and chat
            systems. This has grown exponentially since 2019, as the whole world
            faced (and still faces) travel restrictions and the spread of
            contagious diseases.
          </p>

          <img src={ImgPicture2} alt="img-2" className="w-100 py-5" />

          <h1>Let’s look at the pros and cons:</h1>
          <h3>Employee</h3>

          <p>
            One of the most loathed aspects of office work is before you even
            arrive - the commute. Whether it be by foot, bike, bus, train or
            plane, physical tiredness is proven to impact wellbeing. Remote
            working solves the multiple issues of commuting - no travelling
            reduces physical tiredness and wasting time, extra hours of sleep
            improve cognitive ability and no additional costs. In the comfort of
            your own home, you can be present with your loved ones and take
            regular breaks, such as taking 5 to enjoy breathing in the fresh air
            of your own garden.
          </p>

          <p>
            However, the grass isn’t always greener on the other side. Whilst at
            home, it might be challenging to communicate when it comes to
            managing clients. Clear and consistent communication is needed
            within your teams to ensure no ‘doubling up’ on messages, or worse,
            no communication at all due to the assumption that someone else was
            dealing with it. There have been past reports of video calls getting
            hacked, which can also be a concern when dealing with highly
            confidential material. Socially, it can be hard to feel like part of
            the team when you’re missing lunches out, day trips, company
            parties, birthdays, and end-of-the-week pub trips.
          </p>

          <img src={ImgPicture3} alt="img-3" className="w-100 py-5" />

          <p>
            There will always be challenges working from home, from your lousy
            broadband provider to your unpredictable children/pets, which are
            entertaining to everyone else. Accidents happen, and back-up plans
            need to be put in place if you’re uncontactable and suddenly without
            a working machine. Even though the company may be able to manage,
            and your team can share the load, it’s unfair to rely on them to
            sort your issues - just because you’re working from home.
          </p>

          <p>
            A permanent office space at home will be a must, and somewhere quiet
            where you won’t be interrupted. This is much easier said than done,
            depending on your living situation. It’s also very easy to slip into
            unhealthy habits when working from home, and you may feel yourself
            becoming more withdrawn, both socially and workwise. Fewer meetings
            with friends or teammates can take a toll on an anyone’s mental
            health.
          </p>

          <h3>Employer</h3>

          <p>
            From the employer’s point of view, it can be financially appealing
            to have staff working from home, as it means renting or purchasing
            less office space and lower equipment costs, unless providing desks
            and chairs. It can even reduce additional unseen costs like
            cleaning, utilities, insurance, and maintenance. If a company can
            provide a team of employees from different time zones to offer
            services and support 24 hours around the clock, this gives fantastic
            coverage to clients, offering excellent service which in turn can be
            charged at a higher rate.{" "}
          </p>

          <p>
            However, with employees at home, nothing is face-to-face, and even
            small queries require a call instead of a quick catch up by the
            watercooler. If you’re not reachable when you’re expected to be,
            this can cause bottlenecks and delays on workloads. From a
            cyber-security perspective, it might be a challenge to ensure the
            same guidelines in both the office and WFH staff are being adhered
            to when it comes to leaving PC’s unattended, password safety habits
            and having all anti-virus software up to date.
          </p>

          <p>
            Sometimes sensitive information might be shared during work video
            calls, which can reach ears of visitors or people passing through
            the house, and poor audio quality and background noise might lead to
            miscommunication among the video call participants. Will we ever
            hear the end of “You’re on mute”?{" "}
          </p>
          <img src={ImgPicture4} alt="img-4" className="w-100 py-5" />

          <h1>My Experience? </h1>

          <p>
            Based on my years at Data Kraken, there have been very few issues
            that can’t be solved. Although I’m thousands of miles away, it feels
            like I’m there in the office thanks to frequent friendly messaging
            and the team regularly checking in. I’m included in group chats and
            given opportunities to share my ideas for things such as Birthday
            gifts for other members of the team, event ideas, and fantasy
            football leagues. We spend an hour each week to gather as a team and
            share our thoughts, suggestions and discuss updates on where we are
            as an organization which helps keep me feeling involved and
            motivated. I also received a box of our new merchandise recently
            with some mugs, a hoodie, pens, a cap, and notepad so I can
            represent in my town!
          </p>

          <h1 className="pt-5">Final verdict:</h1>
          <p>
            There’s a lot of things to balance in life, and while there will
            always be advantages and disadvantages, it can be a win-win to work
            from home. As long as you have a company that values you and cares
            about your wellbeing, you’ll be able to find a solution that works
            for both of you.
          </p>
        </Fade>
      </div>
    );
  };

export default Whats_it_like_working_for_an_IT_company_located_in_another_region_of_the_world;
