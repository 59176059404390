import React from "react";
import EnvironmentIcon from "../images/icons/environment.svg";
import TentaclesLeft from "../images/tentacles-about.svg";
import TentaclesRight from "../images/tentacles-about-2.svg";
import Fade from "react-reveal/Fade";

const AboutEnvironment = () => {
  return (
    <section className="about--environment">
      <Fade duration={2000}>
        <div className="container">
          <div className="about--environment--text">
            <img
              src={EnvironmentIcon}
              alt="environment-icon"
              className="about--environment--icon"
            />
            <h1>Our support for the environment</h1>
            <p className="py-4">
              We at Data Kraken are avid believers of helping the environment
              for a better tomorrow. We take pride in actively reducing our
              carbon footprint by sourcing locally, reducing our waste,
              utilising green energy providers and being conscious of our energy
              usage.
            </p>
          </div>
        </div>

        <img
          src={TentaclesLeft}
          alt="tentacles"
          className="about--tentacles--left"
        />
        <img
          src={TentaclesRight}
          alt="tentacles"
          className="about--tentacles--right"
        />
      </Fade>
    </section>
  );
};

export default AboutEnvironment;
