import React from "react";

const BlogPostPreview = ({ Title, Date, Banner, Snippet, URL }) => {
  return (
    <div className="blog--preview p-4">
      <a href={URL}>
        <div className="blog--preview--banner">
          <img src={Banner} className="w-100" alt="blog-banner"></img>
        </div>

        <div className="blog--preview--details pt-4 pb-2">
          <h5 className="pb-2">{Title}</h5>
          <h6>{Date}</h6>
          <p>{Snippet}</p>
        </div>
      </a>
    </div>
  );
};

export default BlogPostPreview;
