import React, { useContext } from "react";
import { WorkListContext } from "../pages/work/WorkList";

import WorkPostPreview from "../components/WorkPostPreview";
import Fade from "react-reveal/Fade";

const WorkListPreview = () => {
  const [WorkList] = useContext(WorkListContext);

  return (
    <div className="container">
      <div className="row">
        {WorkList.map((post) => (
          <Fade duration={2000}>
            <div className="col-12 col-lg-6 p-5" key={post.id}>
              <WorkPostPreview
                Title={post.title}
                Subtitle={post.subtitle}
                Banner={post.banner}
                URL={post.url}
              />
            </div>
          </Fade>
        ))}
      </div>
    </div>
  );
};

export default WorkListPreview;
