import React from "react";

const ServicesCardVertical = ({ icon, heading, description, url }) => {
  return (
    <div className="services--card--vertical">
      <h5 className="card__title">{heading}</h5>
      <div className="row">
        <a href={url}>
          <div className="card__icon__bg hovicon">
            <img
              className="card__icon filer-dk-red"
              src={icon}
              alt="service"
            ></img>
          </div>
        </a>
      </div>

      <div className="row">
        <p className="card__subtitle">{description}</p>
      </div>
    </div>
  );
};

export default ServicesCardVertical;
