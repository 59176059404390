import React from "react";
import WorkHero from "../../layouts/WorkHero";
import ReathLogo from "../../images/work/reath/reath-logo.png";
import ReathBanner from "../../images/work/reath/reath-banner.jpg";
import WorkListSummaryPoints from "../../components/WorkPostSummaryPoints";

import WorkNextPreview from "../../layouts/WorkNextPreview";
import MetaTags from "react-meta-tags";

import Fade from "react-reveal/Fade";

const Reath = () => {
  return (
    <section className="work--post work--reath">
      <MetaTags>
        <title>Reath - Work - Data Kraken</title>
        <meta
          name="description"
          content="Our work with Reath involved the creation of an open data standard for the purpose of facilitating industrial processes involving reusable containers."
        />
      </MetaTags>

      <WorkHero
        Logo={ReathLogo}
        Overview="Our work with Reath involved the creation of an open data standard for the purpose of facilitating industrial processes involving reusable containers."
        Banner={ReathBanner}
      />

      <div className="container">
        <Fade duration={2000}>
          <div className="row">
            <div className="col-12 col-lg-3 order-sm-first">
              <WorkListSummaryPoints
                Client="Reath"
                Industry="Environment"
                Service="Data Presentation"
                Location="England"
              />
            </div>
            <div className="col-12 col-lg-8">
              <h1>About Reath</h1>
              <p>
                Reath’s mission statement is “to build the digital
                infrastructure required for businesses to shift to the circular
                economy. They specialise in the tagging and tracking of circular
                assets, and building the “digital passport” they need - think
                Google Analytics for the circular economy.
              </p>

              <h1 className="pt-5">Our Approach</h1>
              <p>
                The first part of this process involved a detailed requirements
                gathering process. We learned about how circular economy supply
                chains work, the common priorities and pitfalls in such systems,
                and the regulatory requirements their processes are subject to.
                Whenever regulatory compliance is an issue in a data system,
                attention to detail is of the utmost importance, and we were
                careful to ensure that our solution would fit cleanly into both
                the physical and regulatory environments that it is expected to
                perform in.
              </p>
              <p>
                After our requirements gathering process was complete, we began
                the implementation of the open data standard. One aim of the
                standard is to make interoperability between circular economy
                industrial processes easier and towards that end we decided that
                our standard would employ technologies whose enduring and
                widespread use proved their reliability has stood the test of
                time. Our new standard would define both a standard for data
                persistence in the form of a relational database schema and a
                standard for data transfer in the form of a REST API. The REST
                API would be divided into three definitions, an API that
                provides the main developer’s interface and access controls to
                the relational database defined by the standard, an API that
                provides logistical information about items in the process, and
                an API that provides statistical information about the system.
              </p>
              <p>
                Particular attention had to be paid to environment-specific
                matters for example unlike traditional web applications,
                applications conforming to this standard sometimes operate on
                intranets in areas where external internet connections are not
                always available, and mistakes which might be simply annoying in
                ordinary cases could in this instance cause costly regulatory
                issues and are often less easily patched. As well as making sure
                this standard would be applicable in any of these scenarios, it
                was important that we not only got this standard right but that
                we got it right the first time.
              </p>

              <p>
                For much of this work we were able to build upon existing
                technological standards. Our schema definition produced as part
                of this standard was written in compliance with the ISO standard
                for SQL which means that it can be implemented using any
                relational database system conforming to this standard, avoiding
                issues of vendor lock-in in favour of a universal and
                widely-implemented approach. While REST itself is an
                architecture style rather than a standard in its own right, our
                data transfer standard was careful to follow widely accepted
                industry best practices that all web developers are familiar
                with.
              </p>

              <h1 className="pt-5">The Result</h1>
              <p>
                The result of our work was that Reath is able to add an open
                standard for the transfer and storage of data relevant to its
                pioneering work in the circular economy in a way that a diverse
                array of systems can easily integrate into in the future. We
                were very proud of this work at Data Kraken because while this
                was the first open data standard we have developed, it fit very
                well with both our eco-centric values and every developer’s
                desire for standardised data in a sometimes chaotic
                technological landscape.
              </p>
            </div>
          </div>
        </Fade>

        <Fade duration={2000}>
          <div className="row py-5">
            <blockquote className="blockquote">
              <div className="blockquote--dash">
                We contacted the team at Data Kraken when we needed support to
                produce technical documentation for an Open Data Standard. We
                knew they would bring a huge amount of energy and enthusiasm,
                and appreciated the passion and dedication they had for the
                project. They went above-and-beyond to ensure the deliverables
                met the scope, and they communicated progress well throughout.
                <br /> <br /> Claire Rampen, Co-founder & Managing Director
              </div>
            </blockquote>
          </div>
        </Fade>
      </div>

      <WorkNextPreview
        heading="Up next..."
        preview1="Insure and go"
        preview2="O2 Wifi"
      />
    </section>
  );
};

export default Reath;
