import React from "react";
import right_arrow from "../images/icons/right_arrow.svg";

const ServicesCardHorizontal = ({
  id,
  icon,
  service,
  service_details = [],
  preview_img,
  preview_img_width,
  preview_img_padding,
  link,
}) => {
  return (
    <div className={`card--horizontal ${id}`}>
      <a href={link} className="card--link">
        <div className="row">
          <div className="col-12 col-lg-6">
            <img
              className="card__icon filter-dk-grey"
              src={icon}
              alt="card icon"
            ></img>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-9">
            <h4 className="card__title">{service}</h4>
            <ul className="card__list">
              {service_details.map((item) => (
                <li key={item.key}>
                  <p>{item.detail}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <a className="card__proceed filter-dk-grey" href={link}>
          <img src={right_arrow} alt="card proceed"></img>
        </a>

        <img
          className="card__img--main"
          src={preview_img}
          alt="card icon"
        ></img>
      </a>
    </div>
  );
};

export default ServicesCardHorizontal;
