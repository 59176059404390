import React from "react";
import GopePhoto from "../images/team-photos-gope.jpg";
import Fade from "react-reveal/Fade";

const AboutIntro = () => {
  return (
    <section className="about--intro">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6">
            <img src={GopePhoto} alt="gope" className="about--intro--image" />
          </div>
          <Fade duration={1000} distance="10rem" left>
            <div className="col-12 col-lg-6 about--intro--text order-first order-lg-last">
              <h2>Empowering businesses with actionable insights since 2014</h2>
              <p className="py-4">
                After working for blue chip companies for 16 years, Gope was
                disheartened by the lack of innovation in the analytics arm of
                most businesses. The desire to innovate in order to optimise and
                improve businesses using data-driven techniques was rarely seen
                at the level Gope deemed appropriate - hence the birth of Data
                Kraken. <br />
                <br /> Today, the Data Kraken team are working with clients
                across multiple continents, offering data-driven insights that
                allow companies to manage their business as effectively and
                efficiently as possible.
              </p>
            </div>
          </Fade>
        </div>
      </div>
    </section>
  );
};

export default AboutIntro;
