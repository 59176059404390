import React from "react";
import Fade from "react-reveal/Fade";

const ContactMap = () => {
  return (
    <section className="contact--map">
      <Fade duration={2000}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2470.7233086448896!2d-1.2096235844080037!3d51.738096001630396!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876c10d0b151af1%3A0x5167abdf9913d834!2s135%20Hollow%20Way%2C%20Cowley%2C%20Oxford%20OX4%202NE!5e0!3m2!1sen!2suk!4v1619451817555!5m2!1sen!2suk"
          width={600}
          height={500}
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
          title="Map To Office"
        />
      </Fade>
    </section>
  );
};

export default ContactMap;
