import React from "react";
import Hero from "../layouts/Hero";
import ServicesMain from "../layouts/ServicesMain";
import ServicesProcess from "../layouts/ServicesProcess";
import WhoAreYou from "../layouts/FitWhoAreYou";
import WhatAreYouLookingFor from "../layouts/FitLookingFor";
import WhyNow from "../layouts/FitWhyNow";
import HeroBG from "../images/services_hero.svg";
import MetaTags from "react-meta-tags";

const Services = () => {
  return (
    <section className="services">
      <MetaTags>
        <title>Services - Data Kraken</title>
        <meta
          name="description"
          content="Providing services that allow you to take control of your data"
        />
      </MetaTags>
      <Hero
        heading="Providing services that allow you to take control of your data"
        subheading="Our custom solutions ensure your business's data is secure, well-managed and usable."
        icon={HeroBG}
        icon_width="40vw"
        icon_padding="0% 3%"
      />

      <ServicesMain />
      <ServicesProcess />
      <WhoAreYou />
      <WhatAreYouLookingFor />
      <WhyNow />
    </section>
  );
};

export default Services;
