import React from "react";
import WorkHero from "../../layouts/WorkHero";
import O2WifiLogo from "../../images/work/O2Wifi/O2-wifi-logo.png";
import O2WifiBanner from "../../images/work/O2Wifi/O2-wifi-banner.jpg";
import WorkListSummaryPoints from "../../components/WorkPostSummaryPoints";

import WorkNextPreview from "../../layouts/WorkNextPreview";
import MetaTags from "react-meta-tags";

import Fade from "react-reveal/Fade";

const O2Wifi = () => {
  return (
    <section className="work--post work--o2--wifi">
      <MetaTags>
        <title>O2 Wifi - Work - Data Kraken</title>
        <meta
          name="description"
          content="Data Kraken has been working hand in hand with O2 Wifi since 2015. We deliver a full range of services from managing to help their databases to providing actionable insights to help their Business Intelligence and Operational teams."
        />
      </MetaTags>

      <WorkHero
        Logo={O2WifiLogo}
        Overview="Data Kraken has been working hand in hand with O2 Wifi since 2015. We deliver a full range of services from managing to help their databases to providing actionable insights to help their Business Intelligence and Operational teams. "
        Banner={O2WifiBanner}
      />

      <div className="container">
        <Fade duration={2000}>
          <div className="row">
            <div className="col-12 col-lg-3 order-sm-first">
              <WorkListSummaryPoints
                Client="O2 Wifi"
                Industry="Telecommunications"
                Service="Data Collection, Data Management, Data Presentation"
                Location="UK"
              />
            </div>
            <div className="col-12 col-lg-8">
              <h1>About O2 Wifi</h1>
              <p>
                O2 Wifi is a UK nationwide service of O2, which is part of the
                global telecommunications group Telefónica S.A. In 2019, O2 Wifi
                was recognised as the Best Business Network by Mobile Industry
                Awards and as the Most Admired Telecommunications company in
                Europe by Fortune.
              </p>

              <h1 className="pt-5">Challenge</h1>
              <p>
                O2 Wifi wanted to start ingesting a lot more data into their
                Business Intelligence Solution which required a migration from
                Microsoft SQL to MySQL. Also a new data feed had Personal
                Customer Information included which meant we needed to create a
                solution that ensured the data was available to the minimum
                number of processes/developers as well being as secure as
                possible to ensure GDPR requirements were met.
              </p>

              <h1 className="pt-5">Our Work</h1>
              <p>
                Being connected is an integral part of life in the 21st Century.
                Therefore for their customers, O2 Wifi need to ensure their
                service is as optimal as possible. With over 16,000 hotspots in
                the UK, each with multiple pieces of hardware like switches,
                routers and access points, there is always going to be the odd
                problem, i.e. hardware failing, phone line having noise on the
                line, etc.
              </p>

              <p>
                Data Kraken have created a data warehouse to not only get
                salient O2 Wifi data for every hotspot as quickly as possible
                and to identify issues with performance but using our predictive
                modelling and machine learning algorithms, to pre-empt problems
                in the future too.
              </p>

              <p className="pb-5">
                The work proved a success with the Operational Team @ O2 Wifi
                being alerted for current issues and potential future issues
                before the customer noticed a problem.
              </p>
            </div>
          </div>
        </Fade>
      </div>

      <WorkNextPreview
        heading="Up next..."
        preview1="Insure and go"
        preview2="Reath"
      />
    </section>
  );
};

export default O2Wifi;
