import React, { useState, createContext, useContext } from "react";

// Import all blog banners
import banner_three_family_members_with_same_birthday from "../../images/blog/three_family_members_with_same_birthday/banner.jpg";
import banner_exciting_changes_our_new_website from "../../images/blog/exciting_changes_our_new_website/banner.png";
import banner_why_churn_prediction_aitn_churn_prevention from "../../images/blog/why_churn_prediction_aint_churn_prevention/minority-report.png";
import banner_whats_it_like_working_for_an_IT_company_located_in_another_region_of_the_world from "../../images/blog/whats_it_like_working_for_an_IT_company_located_in_another_region_of_the_world/banner_main.png";

// Import all blog components
import Three_family_members_with_same_birthday from "./Three_family_members_with_same_birthday";
import Exciting_changes_our_new_website from "./Exciting_changes_Our_new_website";
import Why_churn_prediction_aint_churn_prevention from "./Why_churn_prediction_aint_churn_prevention";
import Whats_it_like_working_for_an_IT_company_located_in_another_region_of_the_world from "./Whats_it_like_working_for_an_IT_company_located_in_another_region_of_the_world";

// Add new blog header details here
const blogList = [
  {
    id: 4,
    title:
      "What’s it like working for an IT company located in another region of the world? ",
    date: "November 19th 2021",
    author: "Hirosha Liyanage",
    tags: [],
    snippet:
      "Introducing Hirosha, based in Sri Lanka and in his 6th year of working with Data Kraken.",
    banner:
      banner_whats_it_like_working_for_an_IT_company_located_in_another_region_of_the_world,
    url: "whats-it-like-working-for-an-IT-company-located-in-another-region-of-the-world",
    content: (
      <Whats_it_like_working_for_an_IT_company_located_in_another_region_of_the_world />
    ),
  },
  {
    id: 3,
    title: "Why Churn Prediction ain't Churn Prevention",
    date: "September 14th 2021",
    author: "Gope Walker",
    tags: [],
    snippet:
      "As a self-confessed stats geek and CEO of an analytical consultancy, I should probably be the first person to try to sell you the benefits of churn prediction models...",
    banner: banner_why_churn_prediction_aitn_churn_prevention,
    url: "why-churn-prediction-aint-churn-prevention",
    content: <Why_churn_prediction_aint_churn_prevention />,
  },
  {
    id: 2,
    title: "Exciting Changes - Our New Website",
    date: "August 3rd 2021",
    author: "Christian Liu",
    tags: [],
    snippet:
      "We also asked around for what some of our clients and partners thought about us, and it became clear that the old website was missing some of our core characteristics...",
    banner: banner_exciting_changes_our_new_website,
    url: "exciting-changes-our-new-website",
    content: <Exciting_changes_our_new_website />,
  },
  {
    id: 1,
    title: "Three family members with same birthday - what are the odds?",
    date: "July 12th 2021",
    author: "Simon Singh",
    tags: [],
    snippet:
      "The Sun reported a story last year of a baby, mother, and grandmother who all shared the same birthday! Isn’t that remarkable.",
    banner: banner_three_family_members_with_same_birthday,
    url: "three-family-members-with-same-birthday",
    content: <Three_family_members_with_same_birthday />,
  },
];

export const BlogListContext = createContext();

export const BlogListProvider = (props) => {
  return (
    <BlogListContext.Provider value={[blogList]}>
      {props.children}
    </BlogListContext.Provider>
  );
};
