import React from "react";
import LinkedInIcon from "../images/icons/linkedin.svg";
import FacebookIcon from "../images/icons/facebook.svg";
import TwitterIcon from "../images/icons/twitter.svg";
import Fade from "react-reveal/Fade";

const ContactLinks = () => {
  return (
    <section className="contact--links">
      <Fade duration={2000}>
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-12 col-lg-4 contact--links__email">
              <h3>Message Us</h3>
              <p>Speak to us about anything.</p>
              <a href="mailto:hello@datakraken.net">hello@datakraken.net</a>
              <div className="contact--links__email__border"></div>
            </div>
            <div className="col-12 col-lg-4 contact--links__socials">
              <h3>Connect</h3>
              <p>View our socials.</p>
              <a
                href="https://www.linkedin.com/company/data-kraken-consultancy"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={LinkedInIcon}
                  alt="social-icon"
                  className="filter-dk-grey"
                ></img>
              </a>
              <a
                href="https://www.facebook.com/datakraken/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={FacebookIcon}
                  alt="social-icon"
                  className="filter-dk-grey px-2"
                ></img>
              </a>
              <a
                href="https://twitter.com/data_kraken?lang=en"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={TwitterIcon}
                  alt="social-icon"
                  className="filter-dk-grey"
                ></img>
              </a>
              <div className="contact--links__socials__border"></div>
            </div>
            <div className="col-12 col-lg-4 contact--links__address">
              <h3>Our UK Office</h3>
              <p>135 Hollow way, Cowley, Oxford, OX4 2NE</p>
              <a
                href="https://www.google.com/maps?ll=51.738093,-1.207435&z=16&t=m&hl=en&gl=GB&mapclient=embed&daddr=135+Hollow+Way+Cowley+Oxford+OX4+2NE@51.7380927,-1.2074349"
                target="_blank"
                rel="noreferrer"
              >
                Get Directions
              </a>
            </div>
          </div>
        </div>
      </Fade>
    </section>
  );
};

export default ContactLinks;
