import React, { useState, createContext } from "react";

// Import all blog banners
import TuentiArgentinaPreview from "../../images/work/tuenti-argentina/preview.jpg";
import Preview48 from "../../images/work/48/preview.jpg";
import O2WifiPreview from "../../images/work/O2Wifi/preview.jpg";
import InsureAndGoPreview from "../../images/work/insure-and-go/preview.jpg";
import ReathPreview from "../../images/work/reath/preview.jpg";

export const WorkListContext = createContext();

export const WorkListProvider = (props) => {
  // Add work preview details here
  const [workList, setWorkList] = useState([
    {
      id: 1,
      title: "Tuenti",
      subtitle:
        "We assisted Tuenti from the beginning of their journey in South America providing services such as business intelligence and data analytics.",
      banner: TuentiArgentinaPreview,
      url: "/work/tuenti",
    },
    {
      id: 2,
      title: "O2 Wifi",
      subtitle:
        "We deliver a full range of services from managing O2 WiFi’s databases to providing actionable insights to helping their Business Intelligence teams.",
      banner: O2WifiPreview,
      url: "/work/O2-wifi",
    },
    {
      id: 3,
      title: "Insure and go",
      subtitle:
        "We architected and project managed a strategic data warehouse.",
      banner: InsureAndGoPreview,
      url: "/work/insure-and-go",
    },
    {
      id: 4,
      title: "Reath",
      subtitle:
        "We created an open data standard for the purpose of facilitating industrial processes involving reusable containers.",
      banner: ReathPreview,
      url: "/work/reath",
    },
  ]);

  return (
    <WorkListContext.Provider value={[workList, setWorkList]}>
      {props.children}
    </WorkListContext.Provider>
  );
};
