import React, { useState } from "react";
import emailjs from "emailjs-com";
import Fade from "react-reveal/Fade";

const ContactHero = () => {
  const [btnLoad, setBtnLoad] = useState(false);
  const [btnSent, setBtnSent] = useState(false);

  function sendEmail(e) {
    e.preventDefault();
    setBtnLoad(true);

    setTimeout(() => {
      setBtnLoad(false);
      setBtnSent(true);
    }, 1000);

    emailjs
      .sendForm(
        "service_3b08jm2",
        "template_7abqnxg",
        e.target,
        "user_bwYKWP0nvkyjpngijFzyH"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    e.target.reset();
  }

  return (
    <section className="contact--hero">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-12">
            <Fade duration={1500} delay={0}>
              <div className="hero__heading">
                <h1>
                  How can we <br />
                  help?
                </h1>
              </div>
            </Fade>
            <Fade duration={1500} delay={200}>
              <div className="hero__subheading">
                <h6>
                  We’re ready to help you discover what your data can do for
                  you. Ask away...
                </h6>
              </div>
            </Fade>
          </div>

          <div className="col-12 col-lg-5">
            <form className="contact--hero__form" onSubmit={sendEmail}>
              <Fade duration={1500} delay={0}>
                <input
                  type="text"
                  placeholder="Name"
                  name="name"
                  required
                ></input>
              </Fade>
              <Fade duration={1500} delay={50}>
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  required
                ></input>
              </Fade>
              <Fade duration={1500} delay={100}>
                <input
                  type="text"
                  placeholder="Subject"
                  name="subject"
                  required
                ></input>
              </Fade>
              <Fade duration={1500} delay={150}>
                <textarea
                  placeholder="Message"
                  name="message"
                  required
                ></textarea>
              </Fade>
              <Fade duration={1500} delay={200}>
                <button
                  className={`contact--button ${btnLoad ? "onclic" : ""} ${
                    btnSent ? "validate" : ""
                  }`}
                ></button>
              </Fade>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactHero;
