import React from "react";
import Hero from "../layouts/Hero";
import HeroBG from "../images/about_hero.svg";

import AboutIntro from "../layouts/AboutIntro";

import AboutMission from "../layouts/AboutMission";
import AboutVision from "../layouts/AboutVision";

import AboutTeam from "../layouts/AboutTeam";
import AboutEnvironment from "../layouts/AboutEnvironment";

import MetaTags from "react-meta-tags";

const About = () => {
  return (
    <section className="about">
      <MetaTags>
        <title>About - Data Kraken</title>
        <meta
          name="description"
          content="A passionate team of data driven developers & strategists"
        />
      </MetaTags>

      <Hero
        heading="A passionate team of data driven developers & strategists"
        subheading="A team of Data Scientists, Business Intelligence Analysts and Software Developers combine to help you capitalise on the most valuable resource your company has - data."
        icon={HeroBG}
        icon_width="40vw"
      />
      <AboutIntro />

      <AboutMission />
      <AboutVision />
      <AboutTeam />
      <AboutEnvironment />
    </section>
  );
};

export default About;
