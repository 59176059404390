import React from "react";
import MissionIcon from "../images/team-photos-work.jpg";
import Fade from "react-reveal/Fade";

const AboutMission = () => {
  return (
    <section className="about--mission">
      <div className="container">
        <div className="row">
          <Fade duration={1000} distance="10rem" right>
            <div className="col-12 col-lg-6 about--mission__text">
              <h1>Our Mission</h1>
              <p className="py-3">
                Data is the most precious commodity a business has. But
                making sense of data, creating insights and turning them into
                decisions is even more important.
                <br />
                <br />
                Our mission is to help you understand your business’s data and
                help you utilise this information, improving all aspects of your
                business. We always aim to provide the most insightful data
                solutions through our professional, reliable and friendly
                expertise.
              </p>
            </div>
          </Fade>
          <div className="col-12 col-lg-6">
            <img src={MissionIcon} className="w-100" alt="mission"></img>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutMission;
