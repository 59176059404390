import React from "react";
import laptop_mock from "../../images/blog/exciting_changes_our_new_website/laptop_mock.jpg";
import design_mock from "../../images/blog/exciting_changes_our_new_website/page_designs.jpg";
import Fade from "react-reveal/Fade";

const Exciting_changes_our_new_website = () => {
  // Write blog content here
  return (
    <div>
      <Fade duration={1000}>
        <p>
          After a challenging 2020, Data Kraken has been rapidly expanding in
          2021 with new fun and fantastic clients as well as several new bright
          staff joining the team. We're hard at work with all of our internal
          and external projects, to increase our workflow efficiency and give
          the best possible deliverables for our clients. As part of our
          workstack, updating our website to showcase our new product portfolio
          was high on our priority list.
        </p>

        <p className="colour--red">
          <b>
            We had lots of different ideas and we're very excited to share our
            end result!
          </b>
        </p>

        <p>
          The feedback we’d received was that the previous design didn't show
          off enough of what we think makes us special. We also asked around for
          what some of our clients and partners thought about us, and it became
          clear that the website was missing some of our core characteristics
          and qualities. Words and phrases such as:
        </p>

        <p>
          <ul>
            <li>Passionate</li>
            <li>People-first</li>
            <li>Above and beyond</li>
            <li>Pleasure to work with</li>
            <li>Pillar in the growth</li>
            <li>Friendly</li>
            <li>Experienced</li>
          </ul>
        </p>

        <p>
          are what makes us proud to run our company and perform our work in the
          way that we do.
        </p>

        <img src={laptop_mock} className="w-100" alt="laptop"></img>

        <p>
          Big changes were made. We expanded upon some of the features we did
          like in the previous design whilst adding a new host of flair and
          custom icons. We touched up our general brand with a more relevant,
          but still friendly typography scheme. More colours were added
          throughout, making things bolder and fresher. We added in some
          genuine, personable images of the team around the office. All of
          these, wrapped around subtle animations for a more dynamic feel.
        </p>

        <p>
          Plus, we took the time to refine our About Us page. We came together
          and took in all the opinions of our staff, clients and partners to
          really articulate why we're here and doing what we do. We're crazy
          passionate and are proud geeks with data. We love giving the best we
          can for our clients and we want this to reflect on how we're presented
          to the world. We also added in a statement about our strong support
          for the environment - something important to each member of staff at
          Data Kraken.
        </p>

        <p>
          Last, but definitely not least, we have officially started our blog!
          Be sure to follow our socials for updates on all of our new posts!
          We'll be writing about company updates, funky data topics/analysis and
          much more goodies.
        </p>

        <p>
          We hope you enjoy the website as much as we do and we're so excited to
          show you what else we have in store this year! If you have any
          questions or queries feel free to message us at
          <a href="mailto:hello@datakraken.net"> hello@datakraken.net </a>
          and we'll happily get back to you soon!
        </p>

        <img
          src={design_mock}
          style={{ width: "120vw", margin: "5vh -20vw" }}
          alt="family-tree"
          className="py-5"
        ></img>
      </Fade>
    </div>
  );
};

export default Exciting_changes_our_new_website;
