import React from "react";
import LookingFor1 from "../images/looking-for-1.svg";
import LookingFor2 from "../images/looking-for-2.svg";
import LookingFor3 from "../images/looking-for-3.svg";
import Fade from "react-reveal/Fade";

const FitLookingFor = () => {
  return (
    <section className="looking--for">
      <div className="container">
        <Fade duration={2000} delay={0}>
          <h2 className="looking--for--header">What are you looking for?</h2>
        </Fade>

        <div className="row looking--for--text">
          <Fade duration={2000} delay={0}>
            <div className="col-12 col-lg-4 looking--for--text__item">
              <h5>
                KPI / Reporting <br /> Overview
              </h5>
              <p>
                With one glance, it should be possible to see the most important
                information for your business. We can create the reports and
                dashboards to provide you with this information.
              </p>
            </div>
          </Fade>

          <Fade duration={2000} delay={150}>
            <div className="col-12 col-lg-4 looking--for--text__item">
              <h5>
                Customer <br /> understanding
              </h5>
              <p>
                Great sales and service requires knowing your market and
                customers. The foundation for this is having a data-driven
                approach to sales and marketing areas. This requires Business
                Intelligence to be at the heart of your business.
              </p>
            </div>
          </Fade>

          <Fade duration={2000} delay={300}>
            <div className="col-12 col-lg-4 looking--for--text__item">
              <h5>
                Data <br /> solution
              </h5>
              <p>
                Strong Data Governance and a robust Data Architecture will build
                a solid foundation for your business’ information. Having a
                robust and secure Data Warehouse will help eradicate many
                ETL/reporting issues and minimise delays when they do occur.
              </p>
            </div>
          </Fade>
        </div>

        <Fade duration={2000} delay={0}>
          <img src={LookingFor1} alt="" className="looking--for--1" />
          <img src={LookingFor2} alt="" className="looking--for--2" />
          <img src={LookingFor3} alt="" className="looking--for--3" />
        </Fade>
      </div>
    </section>
  );
};

export default FitLookingFor;
