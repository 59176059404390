import React from "react";
import Hero from "../layouts/Hero";
import HeroBG from "../images/data_science_hero.svg";
import DataScienceIcon from "../images/icons/data_science.svg";
import WorkNextPreview from "../layouts/WorkNextPreview";
import Tentacles from "../images/tentacles-service-page.svg";
import Fade from "react-reveal/Fade";
import MetaTags from "react-meta-tags";

const ServicesDataScience = () => {
  return (
    <section className="services--page services--science">
      <MetaTags>
        <title>Services - Data Science - Data Kraken</title>
        <meta
          name="description"
          content="Our team of expert Data Scientists have knowledge in statistical tools and techniques required to create insight and analytics out of your data."
        />
      </MetaTags>

      <Hero
        heading="Data Science"
        subheading="Our team of expert Data Scientists have knowledge in statistical tools and techniques required to create insight and analytics out of your data."
        icon={HeroBG}
        icon_width="40vw"
        icon_padding="0% 3%"
        small_icon={DataScienceIcon}
      />

      <Fade duration={2000}>
        <section className="services--page--info">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-6">
                <div className="row pb-5">
                  <h2>Predictive Modelling and Forecasting</h2>
                  <p>
                    Reporting generally gives you an idea of what has happened
                    whereas Predictive Modelling and Forecasting gives you
                    insight into what will happen. Our team of Data Scientists
                    can create statistical models to give you insight into which
                    customers are going to leave (churn), which are going to
                    have billing problems, which are more likely to buy
                    additional products, etc.
                  </p>
                </div>
                <div className="row pb-5">
                  <h2>Segmentation & Profiling</h2>
                  <p>
                    Differentiation of your customer base through demographics,
                    geographies, behaviours and interests will help you to know
                    your business better.
                  </p>
                </div>
                <div className="row pb-5">
                  <h2>Machine Learning</h2>
                  <p>
                    Machine learning is a method of data analysis that automates
                    analytical model building. It is a branch of artificial
                    intelligence based on the idea that systems can learn from
                    data, identify patterns and make decisions with minimal
                    human intervention.
                  </p>
                </div>
                <div className="row pb-5">
                  <h2>A/B Testing</h2>
                  <p>
                    A/B testing is essentially an experiment where two or more
                    variants of a webpage/campaign are given to users at random,
                    and statistical analysis is used to determine which
                    variation performs better for a given conversion goal. This
                    will ensure your interactions with your customers are as
                    efficient and effective as they can be.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <img src={Tentacles} alt="" className="services--page--tentacles" />
        </section>
      </Fade>

      <Fade duration={2000}>
        <WorkNextPreview
          heading="View examples of our work"
          preview1="Tuenti"
          preview2="O2 Wifi"
        />
      </Fade>
    </section>
  );
};

export default ServicesDataScience;
