import React from "react";
import ProcessArrow from "../images/icons/process--arrow.svg";

import SourcesIcon from "../images/icons/process--sources.svg";
import ImportsIcon from "../images/icons/process--imports.svg";
import ManipulationIcon from "../images/icons/process--manipulation.svg";
import BIIcon from "../images/icons/process--bi.svg";
import OutputIcon from "../images/icons/process--output.svg";

import Fade from "react-reveal/Fade";

const ServicesProcess = () => {
  return (
    <section className="services--process">
      <div className="container">
        <Fade duration={2000}>
          <h1 className="text-center pt-5">
            Our Process
            <br />
          </h1>
        </Fade>

        <div className="services--process__diagram">
          <div className="row row-cols-1 row-cols-lg-5">
            <Fade duration={2000}>
              <div className="process__diagram--item col">
                <img
                  src={SourcesIcon}
                  alt="process-icon"
                  className="diagram--icon"
                />
                <img
                  src={ProcessArrow}
                  alt="process-arrow"
                  className="process--arrow"
                />
                <h5 className="diagram--title">
                  Sources
                  <br />
                  {"\u00A0"}
                </h5>
                <p className="diagram--text">
                  We gather your data from internal or external sources.
                </p>
              </div>
            </Fade>
            <Fade duration={2000} delay={150}>
              <div className="process__diagram--item col">
                <img
                  src={ImportsIcon}
                  alt="process-icon"
                  className="diagram--icon"
                />
                <img
                  src={ProcessArrow}
                  alt="process-arrow"
                  className="process--arrow"
                />
                <h5 className="diagram--title">
                  Imports
                  <br />
                  {"\u00A0"}
                </h5>
                <p className="diagram--text">
                  Data is imported into a data warehouse via API, DB link,
                  flat file or email.
                </p>
              </div>
            </Fade>
            <Fade duration={2000} delay={300}>
              <div className="process__diagram--item col">
                <img
                  src={ManipulationIcon}
                  alt="process-icon"
                  className="diagram--icon"
                />
                <img
                  src={ProcessArrow}
                  alt="process-arrow"
                  className="process--arrow"
                />
                <h5 className="diagram--title">
                  Manipulation
                  <br />
                  {"\u00A0"}
                </h5>
                <p className="diagram--text">
                  To make the data usable, we then cleanse, aggregate and optimise.
                </p>
              </div>
            </Fade>
            <Fade duration={2000} delay={450}>
              <div className="process__diagram--item col">
                <img
                  src={BIIcon}
                  alt="process-icon"
                  className="diagram--icon"
                />
                <img
                  src={ProcessArrow}
                  alt="process-arrow"
                  className="process--arrow"
                />
                <h5 className="diagram--title">
                  Business
                  <br />
                  Intelligence
                </h5>
                <p className="diagram--text">
                  We provide data analytics and models that reveal actionable
                  insights.
                </p>
              </div>
            </Fade>
            <Fade duration={2000} delay={600}>
              <div className="process__diagram--item col">
                <img
                  src={OutputIcon}
                  alt="process-icon"
                  className="diagram--icon"
                />

                <h5 className="diagram--title">
                  Output
                  <br />
                  {"\u00A0"}
                </h5>
                <p className="diagram--text">
                  Your data can now empower your business through reports, analysis, campaigns and
                  more.
                </p>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesProcess;
