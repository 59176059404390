import React from "react";
import logo from "../images/dk_logo_primary.svg";
import MetaTags from "react-meta-tags";

const Error404 = () => {
  return (
    <section className="error-404">
      <MetaTags>
        <title>404 - Data Kraken</title>
        <meta name="description" content="404 not found" />
      </MetaTags>

      <a href="/">
        <img src={logo} alt="dk_logo" className="mb-4" />
      </a>
      <h1 className="p-3">Page not found</h1>
      <a href="/" className="btn btn--red">
        Back to home
      </a>
    </section>
  );
};

export default Error404;
