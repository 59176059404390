import React from "react";
import Fade from "react-reveal/Fade";
import TeamPhotosSimon from "../images/team-photos-simon.jpg";
import TeamPhotosJack from "../images/team-photos-jack.jpg";
import TeamPhotosChristian from "../images/team-photos-christian.jpg";
import TeamPhotosArtur from "../images/team-photos-artur.jpg";
import TeamPhotosAyub from "../images/team-photos-ayub.jpg";

const AboutTeam = () => {
  return (
    <section className="about--team">
      <div className="container">
        <div className="about--team--text">
          <Fade duration={2000}>
            <h1>
              The team making it <br />
              all happen
            </h1>
            <p className="py-4">
              An energetic group that love a challenge, we embrace our inner (or
              outer) geek and are passionate about smart data solutions. We
              strive to communicate effectively, focus on our goals and support
              each other, not forgetting to have fun along the way!
            </p>
            <a href="/contact" className="btn btn--red ml-auto">
              Get in touch
            </a>
          </Fade>
        </div>

        <Fade duration={2000}>
          <img
            src={TeamPhotosSimon}
            alt="team-photos"
            className="about--team--simon"
          />
        </Fade>
        <Fade duration={2000}>
          <img
            src={TeamPhotosJack}
            alt="team-photos"
            className="about--team--jack"
          />
        </Fade>

        <Fade duration={2000}>
          <img
            src={TeamPhotosChristian}
            alt="team-photos"
            className="about--team--christian"
          />
        </Fade>

        <Fade duration={2000}>
          <img
            src={TeamPhotosArtur}
            alt="team-photos"
            className="about--team--artur"
          />
        </Fade>

        <Fade duration={2000}>
          <img
            src={TeamPhotosAyub}
            alt="team-photos"
            className="about--team--ayub"
          />
        </Fade>
      </div>
    </section>
  );
};

export default AboutTeam;
