import React from "react";
import TentaclesLeft from "../images/tentacles-homepage-contact.svg";
import TentaclesRight from "../images/tentacles-homepage-contact-2.svg";

import Fade from "react-reveal/Fade";

const HomepageContact = () => {
  return (
    <section className="homepage--contact">
      <div className="container">
        <Fade duration={2000}>
          <div className="row">
            <h1 className="text-center">
              See how we can <br />
              help you <span className="colour--red">today</span>
            </h1>
          </div>

          <div className="row d-flex justify-content-center">
            <a href="/contact" className="btn btn--red mt-5">
              Get in touch
            </a>
          </div>

          <img
            src={TentaclesLeft}
            alt="tentacles-homepage-contact"
            className="tentacles--left"
          />
          <img
            src={TentaclesRight}
            alt="tentacles-homepage-contact"
            className="tentacles--right"
          />
        </Fade>
      </div>
    </section>
  );
};

export default HomepageContact;
