import React from "react";
import WorkIcon from "../images/tentacles-homepage-work.svg";
import Fade from "react-reveal/Fade";

const HomepageWork = () => {
  return (
    <section className="homepage--work">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6"></div>
          <div className="col-12 col-lg-6 homepage--work__text">
            <Fade duration={2000}>
              <h1>
                We take pride in
                <br /> our work
              </h1>
            </Fade>

            <Fade duration={2000}>
              <p className="py-3">
                However large or small your business is, all of our customers
                receive the highest standard of service. Honest, highly-rated
                and reliable, why not see what Data Kraken can do for you?
              </p>
            </Fade>
            <Fade duration={2000}>
              <a href="/work" className="btn btn--white">
                Our case studies
              </a>
            </Fade>
          </div>
        </div>
      </div>
      <Fade duration={2000}>
        <img
          src={WorkIcon}
          className="homepage--work__icon"
          alt="work-icon"
        ></img>
      </Fade>
    </section>
  );
};

export default HomepageWork;
