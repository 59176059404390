import React from "react";
import ServicesCardHorizontal from "../components/ServicesCardHorizontal";

import DataPresentationIcon from "../images/icons/data_presentation.svg";
import DataScienceIcon from "../images/icons/data_science.svg";
import DataManagementIcon from "../images/icons/data_management.svg";
import DataCollectionIcon from "../images/icons/data_collection.svg";

import DataCollectionPreview from "../images/data_collection_preview.svg";
import DataSciencePreview from "../images/data_science_preview.svg";
import DataManagementPreview from "../images/data_management_preview.svg";
import DataPresentationPreview from "../images/data_presentation_preview.svg";

import Fade from "react-reveal/Fade";

const ServicesMain = () => {
  return (
    <section className="services--main">
      <div className="container">
        <div className="row pb-5">
          <div className="col-12 col-lg-6">
            <Fade duration={2000}>
              <ServicesCardHorizontal
                id="data-collection"
                icon={DataCollectionIcon}
                service="Data Collection"
                service_details={[
                  {
                    key: 1,
                    detail: "Data Migration",
                  },
                  {
                    key: 2,
                    detail: "Automated Imports",
                  },
                  {
                    key: 3,
                    detail: "Big Data",
                  },
                  {
                    key: 4,
                    detail: "API Integration",
                  },
                ]}
                link="/services/data-collection"
                preview_img={DataCollectionPreview}
              />
            </Fade>
          </div>
          <div className="col-12 col-lg-6">
            <Fade duration={2000} delay={200}>
              <ServicesCardHorizontal
                id="data-science"
                icon={DataScienceIcon}
                service="Data Science"
                service_details={[
                  {
                    key: 1,
                    detail: "Predictive Modelling",
                  },
                  {
                    key: 2,
                    detail: "Forecasting",
                  },
                  {
                    key: 3,
                    detail: "Segmentation & Profiling",
                  },
                  {
                    key: 4,
                    detail: "Machine Learning",
                  },
                  {
                    key: 5,
                    detail: "A/B Testing",
                  },
                ]}
                link="/services/data-science"
                preview_img={DataSciencePreview}
              />
            </Fade>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-lg-6">
            <Fade duration={2000}>
              <ServicesCardHorizontal
                id="data-management"
                icon={DataManagementIcon}
                service="Data Management"
                service_details={[
                  {
                    key: 1,
                    detail: "Operational Support",
                  },
                  {
                    key: 2,
                    detail: "Cloud Hosting",
                  },
                  {
                    key: 3,
                    detail: "GDPR Compliance / Security",
                  },
                  {
                    key: 4,
                    detail: "Patching / Optimisation",
                  },
                ]}
                link="/services/data-management"
                preview_img={DataManagementPreview}
              />
            </Fade>
          </div>
          <div className="col-12 col-lg-6">
            <Fade duration={2000} delay={200}>
              <ServicesCardHorizontal
                id="data-presentation"
                icon={DataPresentationIcon}
                service="Data Delivery"
                service_details={[
                  {
                    key: 1,
                    detail: "Analytical Reporting",
                  },
                  {
                    key: 2,
                    detail: "Dashboards",
                  },
                  {
                    key: 3,
                    detail: "CRM",
                  },
                ]}
                link="/services/data-delivery"
                preview_img={DataPresentationPreview}
              />
            </Fade>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesMain;
