import React from "react";
import LinkedInIcon from "../images/icons/linkedin.svg";
import FacebookIcon from "../images/icons/facebook.svg";
import TwitterIcon from "../images/icons/twitter.svg";
import MainLogo from "../images/dk_logo_no_text.svg";
import UpArrowRound from "../images/icons/up_arrow_round.svg";
import Fade from "react-reveal/Fade";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-4 order-2 order-lg-1">
            <Fade duration={1500} delay={0}>
              <ul className="footer--sitemap">
                <li>
                  <h5>
                    <a href="/" className="footer--sitemap__link">
                      Home
                    </a>
                  </h5>
                </li>
                <li>
                  <h5>
                    <a href="/about" className="footer--sitemap__link">
                      About
                    </a>
                  </h5>
                </li>
                <li>
                  <h5>
                    <a href="/services" className="footer--sitemap__link">
                      Services
                    </a>
                  </h5>
                </li>
                <li>
                  <h5>
                    <a href="/blog" className="footer--sitemap__link">
                      Blog
                    </a>
                  </h5>
                </li>
                <li>
                  <h5>
                    <a href="/work" className="footer--sitemap__link">
                      Work
                    </a>
                  </h5>
                </li>
                <li>
                  <h5>
                    <a href="/contact" className="footer--sitemap__link">
                      Contact
                    </a>
                  </h5>
                </li>
                <li>
                  <h5>
                    <a href="/privacy-policy" className="footer--sitemap__link">
                      Privacy Policy
                    </a>
                  </h5>
                </li>
              </ul>
            </Fade>
          </div>
          <div className="col-12 col-lg-4 order-3 order-lg-2 footer--contact">
            <Fade duration={1500} delay={200}>
              <div className="row footer--contact__address">
                <h5>
                  135 Hollow Way
                  <br />
                  Cowley
                  <br />
                  Oxford, OX4 2NE
                  <br />
                  United Kingdom
                  <br />
                </h5>
              </div>
              <div className="row footer--contact__socials">
                <a
                  href="https://www.linkedin.com/company/data-kraken-consultancy"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={LinkedInIcon} alt="linkedin"></img>
                </a>
                <a
                  href="https://www.facebook.com/datakraken/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={FacebookIcon} alt="facebook"></img>
                </a>
                <a
                  href="https://twitter.com/data_kraken?lang=en"
                  target="_blank"
                  alt="twitter"
                  rel="noreferrer"
                >
                  <img src={TwitterIcon} alt="twitter"></img>
                </a>
              </div>
            </Fade>
          </div>
          <div className="col-12 col-lg-4 order-1  order-lg-3 footer--logo">
            <Fade duration={1500} delay={300}>
              <div className="row">
                <p
                  className="footer--backtotop"
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                >
                  Back to top
                  <img
                    src={UpArrowRound}
                    className="footer--backtotop__icon"
                    alt="btp"
                  ></img>
                </p>
              </div>
              <div className="row footer--email">
                <h3>
                  <a href="mailto:hello@datakraken.net" className="text-white">
                    hello@datakraken.net
                  </a>
                </h3>
              </div>
              <div className="row footer--logo__icon">
                <img src={MainLogo} alt="logo"></img>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
