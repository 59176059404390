import React from "react";
import Hero from "../layouts/Hero";
import HeroBG from "../images/data_presentation_hero.svg";
import DataPresentationIcon from "../images/icons/data_presentation.svg";
import WorkNextPreview from "../layouts/WorkNextPreview";
import Tentacles from "../images/tentacles-service-page.svg";
import Fade from "react-reveal/Fade";
import MetaTags from "react-meta-tags";

const ServicesDataPresentation = () => {
  return (
    <section className="services--page services--presentation">
      <MetaTags>
        <title>Services - Data Delivery - Data Kraken</title>
        <meta
          name="description"
          content="Data Delivery is the whole purpose of building a Data
          Solution. Once your data is in a safe and reliable state,
          there are several types of delivery that can help unlock
          your business’s potential."
        />
      </MetaTags>

      <Hero
        heading="Data Delivery"
        subheading="Data Delivery is the whole purpose of building a Data
        Solution. Once your data is in a safe and reliable state,
        there are several types of delivery that can help unlock
        your business’s potential."
        icon={HeroBG}
        icon_width="40vw"
        icon_padding="0% 3%"
        small_icon={DataPresentationIcon}
      />

      <Fade duration={2000}>
        <section className="services--page--info">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-6">
                <div className="row pb-5">
                  <h2>Analytical Reporting</h2>
                  <p>
                    For any business to run efficiently, understanding what is
                    going on under the hood is paramount. This could include
                    reporting across all directorates such as:
                  </p>

                  <p>
                    <ul>
                      <li>Sales vs Budget by product/channel</li>
                      <li>Sales funnel reporting & optimisation</li>
                      <li>
                        Product utilisation reporting (for service industry)
                      </li>
                      <li>SEO reporting</li>
                    </ul>
                  </p>
                </div>

                <div className="row pb-5">
                  <h2>Dashboards</h2>
                  <p>
                    Whereas Analytical Reporting are generally used for
                    strategic insight, dashboards on the other hand are very
                    useful for a quick glance at how your business is performing
                    as well as to give real-time operational insight on how your
                    business is currently functioning. Here at Data Kraken, we
                    have expertise in many BI tools such as Microsoft Power BI
                    as well as creating our own dashboards using internal
                    software.
                  </p>
                </div>
                <div className="row pb-5">
                  <h2>Customer Relationship Management (CRM)</h2>
                  <p>
                    CRM helps businesses build a relationship with their
                    customers that help create loyalty, advocacy and retention.
                    With so much marketing being exposed to all of us today,
                    speaking to your customers in a timely, accurate and
                    relevant manner has never been more important. Data Kraken
                    can help unlock the potential of your data which will not
                    only help you understand your customers better but also
                    build a better and stronger relationship.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <img src={Tentacles} alt="" className="services--page--tentacles" />
        </section>
      </Fade>

      <Fade duration={2000}>
        <WorkNextPreview
          heading="View examples of our work"
          preview1="48 Ireland"
          preview2="Reath"
        />
      </Fade>
    </section>
  );
};

export default ServicesDataPresentation;
