import React from "react";
import WhoAreYouIcon from "../images/fit-who-are-you.svg";
import WhoAreYouWidget from "../images/who-are-you-widget.svg";
import Fade from "react-reveal/Fade";

const FitWhoAreYou = () => {
  return (
    <section className="who--are--you">
      <div className="container">
        <Fade duration={2000} delay={0}>
          <h1 className="who--are--you--needs">
            Your <span className="colour--red">Needs</span>
          </h1>
        </Fade>

        <div className="row pt-5">
          <div className="col-0 col-lg-4 d-flex">
            <Fade duration={2000} delay={0}>
              <img
                src={WhoAreYouIcon}
                alt="who-are-you"
                className="who--are--you--icon"
              />{" "}
            </Fade>
          </div>
          <div className="col-12 col-lg-8">
            <Fade duration={2000} delay={0}>
              <h2 className="who--are--you--heading">Who Are You?</h2>
              <div className="row">
                <div className="col-12 col-lg-6">
                  <div className="row who--are--you--text">
                    <h5>Executive Manager</h5>
                    <p>
                      Are you unable to get the right KPIs on how your business
                      is performing? Having a solid data strategy in place can
                      help you convert your masses of data into valuable and
                      easily digestable insight.
                    </p>
                  </div>

                  <div className="row pt-5 who--are--you--text">
                    <h5>Data Manager</h5>
                    <p>
                      You may have data spread across different systems, areas
                      and teams. Having a consolidated centralised system will
                      reduce overheads and stress. We can help architect, build
                      and manage a solution to suit your needs.
                    </p>
                  </div>
                </div>

                <div className="col-12 col-lg-6">
                  <div className="who--are--you--marketing">
                    <h5>Marketing/Sales Manager</h5>
                    <p>
                      If you had £1 extra to spend, where exactly would you
                      spend it to maximise your return? Increasing Google Ad
                      budget? Giving customer discounts? Spend within customer
                      service? If you don’t know, we can help you answer this.
                    </p>
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </div>
      <Fade duration={2000} delay={0}>
        <img src={WhoAreYouWidget} alt="" className="who--are--you--widget" />
      </Fade>
    </section>
  );
};

export default FitWhoAreYou;
