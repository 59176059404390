import React, { useContext } from "react";
import BlogPostHeader from "../layouts/BlogPostHeader";
import BlogPostContent from "../layouts/BlogPostContent";
import BlogNextPosts from "../layouts/BlogNextPosts";
import MetaTags from "react-meta-tags";
import Fade from "react-reveal/Fade";
import Tentacles from "../images/tentacles-blog-post.svg";

import { BlogListContext } from "./blog/BlogList";

const BlogPost = ({ URL }) => {
  const [blogList] = useContext(BlogListContext);

  // Find blog post from context provider
  let post = blogList.filter((post) => post.url === URL)[0];

  // Set header & content info
  const BlogHeader = {
    id: post.id,
    title: post.title,
    date: post.date,
    author: post.author,
    banner: post.banner,
    snippet: post.snippet,
    url: post.url,
  };

  const BlogContent = post.content;

  // Find next and last blog post
  let BlogNext =
    blogList.filter((nextPost) => nextPost.id === post.id + 1)[0] ||
    blogList.filter((nextPost) => nextPost.id === post.id - 2)[0];
  let BlogPrev =
    blogList.filter((prevPost) => prevPost.id === post.id - 1)[0] ||
    blogList.filter((prevPost) => prevPost.id === post.id + 2)[0];

  return (
    <section className="blog--post">
      <MetaTags>
        <title>{BlogHeader.title} - Blog - Data Kraken</title>
        <meta name="description" content={BlogHeader.snippet} />
        <meta property="og:title" content={BlogHeader.title} />
        <meta property="og:description" content={BlogHeader.snippet} />
        <meta
          property="og:url"
          content={"www.datakraken.net/" + BlogHeader.url}
        />
        <meta
          property="og:image"
          content={`${window.location.origin}${BlogHeader.banner}`}
        />
        <meta property="og:type" content="article" />
      </MetaTags>

      <Fade duration={2000}>
        <img src={Tentacles} alt="tentacles-blog" className="tentacles" />
      </Fade>

      <div className="container">
        <div className="row pb-5">
          <div className="col-lg-7 col-12">
            <BlogPostHeader BlogHeader={BlogHeader} />
            <BlogPostContent
              BlogContent={BlogContent}
              BlogHeader={BlogHeader}
            />
          </div>
        </div>
      </div>

      <BlogNextPosts BlogPrev={BlogPrev} BlogNext={BlogNext} />
    </section>
  );
};

export default BlogPost;
