import React from "react";
import BlogPostPreview from "./../components/BlogPostPreview";
import Fade from "react-reveal/Fade";

const BlogNextPosts = ({ BlogNext, BlogPrev }) => {
  return (
    <section className="blog--post--next py-5">
      <Fade duration={2000}>
        <div className="container">
          <h3 className="py-5">View our other posts</h3>
          <div className="row">
            <div className="col-12 col-sm-6">
              <BlogPostPreview
                Title={BlogPrev.title}
                Date={BlogPrev.date}
                Author={BlogPrev.author}
                Banner={BlogPrev.banner}
                Snippet={BlogPrev.snippet}
                URL={BlogPrev.url}
              />
            </div>

            <div className="col-12 col-sm-6">
              <BlogPostPreview
                Title={BlogNext.title}
                Date={BlogNext.date}
                Author={BlogNext.author}
                Banner={BlogNext.banner}
                Snippet={BlogNext.snippet}
                URL={BlogNext.url}
              />
            </div>
          </div>
        </div>
      </Fade>
    </section>
  );
};

export default BlogNextPosts;
