import React, { useRef } from "react";

import ReathLogo from "../images/work/reath/reath-logo.png";
import InsureAndGoLogo from "../images/work/insure-and-go/insure-and-go-logo.png";
import TuentiArgentinaLogo from "../images/work/tuenti-argentina/tuenti-logo.png";
import Mobile48Logo from "../images/work/48/48-logo.png";

import TestimonialPrevIcon from "../images/icons/testimonial--prev.svg";
import TestimonialNextIcon from "../images/icons/testimonial--next.svg";

import Slider from "react-slick";

import Fade from "react-reveal/Fade";
import Tentacles from "../images/tentacles-testimonials.svg";

const Testimonials = () => {
  const sliderRef = useRef();

  const gotoNext = () => {
    sliderRef.current.slickNext();
  };

  const gotoPrev = () => {
    sliderRef.current.slickPrev();
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: false,
    adaptiveHeight: false,
    arrows: false,
  };

  return (
    <section className="testimonials">
      <div className="container">
        <Fade duration={2000}>
          <img
            src={Tentacles}
            alt="tentacles-testimonials"
            className="tentacles"
          />
        </Fade>

        <Fade duration={2000}>
          <h1 className="text-center testimonials--header">
            What our <span className="colour--red">clients </span>
            say <br /> about us
          </h1>
        </Fade>

        <hr className="dk--divider" />

        <Fade duration={2000}>
          <Slider {...settings} ref={sliderRef}>
            <div className="slide py-5">
              <div className="row py-5">
                <div className="col-12">
                  <div className="testimonial--container py-5">
                    <div className="row">
                      <div className="col-1 col-lg-3">
                        <h1 className="testimonial--container__icon">“</h1>
                      </div>

                      <div className="col-12 col-lg-9">
                        <p className="testimonial--container__quote">
                          We have been working together for several years, and
                          the geographical distance has never been a barrier to
                          implement large projects. Their service and support
                          have been a pillar in the growth of Tuenti, and helped
                          us identify business opportunities that we may have
                          missed without them.
                        </p>
                      </div>

                      <div className="row pt-3">
                        <div className="col-6 col-lg-3">
                          <img
                            src={TuentiArgentinaLogo}
                            alt=""
                            className="testimonial--container__logo"
                          />
                        </div>

                        <div className="col-6 col-lg-9">
                          <h6 className="testimonial--container__author">
                            Marketing & BI Team,
                            <br />
                            Tuenti Argentina
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="slide py-5">
              <div className="row py-5">
                <div className="col-12">
                  <div className="testimonial--container py-5">
                    <div className="row">
                      <div className="col-1 col-lg-3">
                        <h1 className="testimonial--container__icon">“</h1>
                      </div>

                      <div className="col-12 col-lg-9">
                        <p className="testimonial--container__quote">
                          We knew Data Kraken would bring a huge amount of
                          energy and enthusiasm, and appreciated the passion and
                          dedication they had for the project. They went
                          above-and-beyond to ensure the deliverables met the
                          scope, and they communicated progress well throughout.
                        </p>
                      </div>

                      <div className="row pt-3">
                        <div className="col-6 col-lg-3">
                          <img
                            src={ReathLogo}
                            alt=""
                            className="testimonial--container__logo"
                          />
                        </div>

                        <div className="col-6 col-lg-9">
                          <h6 className="testimonial--container__author">
                            Claire Rampen,
                            <br />
                            Co-founder & Managing Director
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="slide py-5">
              <div className="row py-5">
                <div className="col-12">
                  <div className="testimonial--container py-5">
                    <div className="row">
                      <div className="col-1 col-lg-3">
                        <h1 className="testimonial--container__icon">“</h1>
                      </div>

                      <div className="col-12 col-lg-9">
                        <p className="testimonial--container__quote">
                          Data Kraken go over and beyond to share their
                          knowledge and expertise of the Telco industry by
                          highlighting and extracting new and exciting insights
                          from our data. It is clear they really care about
                          their clients. They always deliver on time and are
                          enthusiastic to collaborate on all projects big and
                          small, and best of all are a pleasure to work with.
                        </p>
                      </div>

                      <div className="row pt-3">
                        <div className="col-6 col-lg-3">
                          <img
                            src={Mobile48Logo}
                            alt=""
                            className="testimonial--container__logo w-50"
                          />
                        </div>

                        <div className="col-6 col-lg-9">
                          <h6 className="testimonial--container__author">
                            Joanne Bracken,
                            <br />
                            48 Lead Manager
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="slide py-5">
              <div className="row py-5">
                <div className="col-12">
                  <div className="testimonial--container py-5">
                    <div className="row">
                      <div className="col-1 col-lg-3">
                        <h1 className="testimonial--container__icon">“</h1>
                      </div>

                      <div className="col-12 col-lg-9">
                        <p className="testimonial--container__quote">
                          Thanks to comprehensive data analysis delivered
                          swiftly by Data Kraken, we have achieved extraordinary
                          results, becoming one of the top three mobile brands
                          in Ecuador and gaining an NPS score (measure of
                          customer satisfaction) of 70%, a truly unique score in
                          the telecoms industry.
                        </p>
                      </div>

                      <div className="row pt-3">
                        <div className="col-6 col-lg-3">
                          <img
                            src={TuentiArgentinaLogo}
                            alt=""
                            className="testimonial--container__logo"
                          />
                        </div>

                        <div className="col-6 col-lg-9">
                          <h6 className="testimonial--container__author">
                            Carlos Jaime
                            <br />
                            Head of IT, Tuenti Ecuador
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="slide py-5">
              <div className="row py-5">
                <div className="col-12">
                  <div className="testimonial--container py-5">
                    <div className="row">
                      <div className="col-1 col-lg-3">
                        <h1 className="testimonial--container__icon">“</h1>
                      </div>

                      <div className="col-12 col-lg-9">
                        <p className="testimonial--container__quote">
                          Data Kraken has a wealth of experience managing data
                          needs for different industries. From day one, Gope
                          understood our strategy, needs and context. He was
                          fully involved in helping us to get over the line, by
                          supporting and mentoring the team and working with the
                          stakeholders in the company.
                        </p>
                      </div>

                      <div className="row pt-3">
                        <div className="col-6 col-lg-3">
                          <img
                            src={InsureAndGoLogo}
                            alt=""
                            className="testimonial--container__logo"
                          />
                        </div>

                        <div className="col-6 col-lg-9">
                          <h6 className="testimonial--container__author">
                            Ana Vilda,
                            <br />
                            Chief Information Officer
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </Fade>

        <Fade duration={2000}>
          <div className="testimonials--controls pb-5">
            <button className="testimonials--prev" onClick={() => gotoPrev()}>
              <img src={TestimonialPrevIcon} alt="" />
            </button>
            <button className="testimonials--next" onClick={() => gotoNext()}>
              <img src={TestimonialNextIcon} alt="" />
            </button>
          </div>
        </Fade>
      </div>
    </section>
  );
};

export default Testimonials;
