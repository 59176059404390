import React from "react";
import WorkHero from "../../layouts/WorkHero";
import TuentiLogo from "../../images/work/tuenti-argentina/tuenti-logo.png";
import TuentiBanner from "../../images/work/tuenti-argentina/tuenti-banner.jpg";
import WorkListSummaryPoints from "../../components/WorkPostSummaryPoints";

import WorkNextPreview from "../../layouts/WorkNextPreview";
import MetaTags from "react-meta-tags";

import Fade from "react-reveal/Fade";

const TuentiArgentina = () => {
  return (
    <section className="work--post work--tuenti--argentina">
      <MetaTags>
        <title>Tuenti - Work - Data Kraken</title>
        <meta
          name="description"
          content="Data Kraken assisted Tuenti from the beginning of their journey to Latin America in 2015 when they launched providing crucial services such as business intelligence and data analytics. Tuenti are a unique brand and Data Kraken are proud to work with them."
        />
      </MetaTags>

      <WorkHero
        Logo={TuentiLogo}
        Overview="Data Kraken assisted Tuenti from the beginning of their journey to Latin America in 2015 when they launched providing crucial services such as business intelligence and data analytics. Tuenti are a unique brand and Data Kraken are proud to work with them."
        Banner={TuentiBanner}
      />

      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-3 order-sm-first">
            <WorkListSummaryPoints
              Client="Tuenti"
              Industry="MVNOs"
              Service="Data Science, Data Management, Data Presentation"
              Location="Argentina, Ecuador, Guatemala, Peru"
            />
          </div>
          <div className="col-12 col-lg-8">
            <h1>About Tuenti</h1>
            <p>
              A 100% digital telephone company, Tuenti launched in several Latin
              American countries during 2014. Aiming to pave the way for fresh,
              new, and simple phone plans, Tuenti arrived in their market to
              shake things up. After gaining more than a million clients, it is
              safe to say they are extremely successful.
            </p>

            <h1 className="pt-5">Challenge</h1>
            <p>
              One of the biggest challenges we faced was providing a secure and
              effective environment for Business Intelligence that works
              seamlessly to provide actionable insights for companies from each
              country. From one thousand customers in 2015, to over one million
              in 2020, we created scalable systems to easily accommodate
              Tuenti’s ever-growing customer base.
            </p>

            <h1 className="pt-5">Growth & Adaptation</h1>
            <p>
              When a company first launches, there may not be much in-depth
              analysis required. For example, knowing the number of products
              bought per city, per day, might suffice initially. However, as
              soon as the client base increases, so does the complexity of the
              information needed to be delivered - this is where Data Kraken
              steps in. For example, a customer buys a product and the company
              needs to check which promotion would be best-suited to this
              customer. From the customer data, we can see that 100% of their
              minutes in the last three months have been used. We can rapidly
              inform our client and from this, the company are able to offer a
              tailored promotion that is most likely to be successful, such as
              ‘Extra Minutes’.
            </p>

            <p>
              For bespoke service that deals with terabytes of data efficiently,
              a multitude of processes work together. A quick reception and
              digestion of the data from the billing system, categorisation of
              the client and communication. All the links of this chain must be
              robust for this solution to be delivered effectively.
            </p>

            <h1 className="pt-5">Client requests</h1>

            <p>
              Regardless of what a business needs, when a client asks us to do
              something different, we are willing to deliver our best as quickly
              as we can. You may not know what you want straight away, or
              realise how much visualised data could help your business. We
              constantly strive to proactively find solutions and reports that
              can help our clients and client’s customers. The services provided
              to Tuenti are varied and adaptive: from creating quality reports,
              analysing customer data, managing independent servers for other
              applications in Tuenti, alerting of issues detected in the data,
              ad-hoc investigations to model client usage, whilst also working
              on improvements in the structure of the data warehouse. Here at
              Data Kraken, we are happy to adapt our services for the
              ever-evolving industry that Tuenti excels in.
            </p>
          </div>
        </div>

        <Fade duration={2000}>
          <div className="row py-5">
            <blockquote className="blockquote">
              <div className="blockquote--dash">
                Data Kraken and Tuenti Argentina have been working together for
                several years, and the geographical distance has never been a
                barrier to implement large analytical projects. As our company
                grew, the team at DK were always willing to provide us with
                solutions and proposals for continuous improvement, to help us
                better understand our customers.
                <br />
                <br />
                Their service and support have been a pillar in the growth of
                Tuenti, and helped us identify business opportunities that we
                may have missed without them.
                <br /> <br />— Marketing & BI Team, Tuenti Argentina
              </div>
            </blockquote>
          </div>

          <div className="row py-5">
            <blockquote className="blockquote">
              <div className="blockquote--dash">
                The highly competitive telecommunications market led us to
                realise that to succeed as a business, we needed to better
                understand the market and our customers. <br />
                <br />
                Data interpretation plays a key role in Tuenti Ecuador, and Data
                Kraken can provide data reports daily, weekly, monthly, and even
                in real time. This allows us to better understand the needs of
                our clients and offer them services according to what we can see
                is important to them. <br />
                <br />
                Thanks to comprehensive data analysis delivered swiftly by Data
                Kraken, we have achieved extraordinary results, becoming one of
                the top three mobile brands in Ecuador and gaining an NPS score
                (measure of customer satisfaction) of 70%, a truly unique score
                in the telecoms industry. <br />
                <br /> These achievements are the result of many things coming
                together at the right time, including having an excellent
                provider such as Data Kraken assisting with data analysis and
                automation solutions. They bring value to our business and
                continue to make our customers fall in love.
                <br /> <br />— Carlos Jaime, Head of IT , Tuenti Ecuador
              </div>
            </blockquote>
          </div>
        </Fade>
      </div>

      <WorkNextPreview
        heading="Up next..."
        preview1="48 Ireland"
        preview2="O2 Wifi"
      />
    </section>
  );
};

export default TuentiArgentina;
