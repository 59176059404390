import React from "react";
import Fade from "react-reveal/Fade";

const Hero = ({
  heading,
  subheading,
  cta,
  icon,
  icon_width,
  icon_padding,
  small_icon,
  icon_mobile,
  particles,
}) => {
  return (
    <section className="hero">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-12">
            <div className="hero__heading">
              <Fade duration={1500}>
                <img
                  src={small_icon}
                  alt=""
                  className="py-3 hero__heading--icon"
                />
                <h1>{heading}</h1>
              </Fade>
            </div>
            <Fade delay={200} duration={1500}>
              <div className="hero__subheading">
                <h6>{subheading}</h6>
              </div>
            </Fade>

            {cta ? (
              <Fade delay={400} duration={1500}>
                <div className="hero__cta">
                  <a href="/services" className="btn btn--red">
                    {cta}
                  </a>
                </div>
              </Fade>
            ) : null}
          </div>
        </div>
      </div>

      {particles ? (
        <Fade duration={2500} delay={100}>
          <img
            src={particles}
            className="hero__icon--desktop--squares"
            alt="particles"
          ></img>
        </Fade>
      ) : (
        <div></div>
      )}

      {icon_mobile ? (
        <Fade duration={1500} delay={0}>
          <img
            src={icon}
            className="hero__icon--desktop"
            style={{ width: icon_width, padding: icon_padding }}
            alt=""
          ></img>
          <img
            src={icon_mobile}
            className="hero__icon--mobile"
            style={{ width: icon_width, padding: icon_padding }}
            alt=""
          ></img>
        </Fade>
      ) : (
        <Fade duration={1500} delay={0}>
          <img
            src={icon}
            className="hero__icon"
            style={{ width: icon_width, padding: icon_padding }}
            alt=""
          ></img>
        </Fade>
      )}
    </section>
  );
};

export default Hero;
