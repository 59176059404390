import React, { useContext } from "react";
import { BlogListContext } from "../pages/blog/BlogList";
import BlogPostPreview from "../components/BlogPostPreview";
import Fade from "react-reveal/Fade";
import Tentacles from "../images/tentacles-blog.svg";

const BlogList = () => {
  const [blogList] = useContext(BlogListContext);

  return (
    <section className="blog--list--preview">
      <div className="container">
        <div className="row py-5">
          {blogList.map((post) => (
            <Fade duration={2000}>
              <div className="col-12 col-lg-6">
                <BlogPostPreview
                  Title={post.title}
                  Date={post.date}
                  Author={post.author}
                  Banner={post.banner}
                  Snippet={post.snippet}
                  URL={"/blog/" + post.url}
                />
              </div>
            </Fade>
          ))}
        </div>
      </div>

      <Fade duration={2000}>
        <img src={Tentacles} alt="tentacles-blog" className="tentacles" />
      </Fade>
    </section>
  );
};

export default BlogList;
