import React from "react";
import ServiceCard from "../components/ServicesCardVertical";
import Fade from "react-reveal/Fade";

import DataPresentationIcon from "../images/icons/data_presentation.svg";
import DataScienceIcon from "../images/icons/data_science.svg";
import DataManagementIcon from "../images/icons/data_management.svg";
import DataCollectionIcon from "../images/icons/data_collection.svg";

import Tentacles from "../images/tentacles-homepage-services.svg";

const ServicesHomepage = () => {
  return (
    <Fade duration={2000}>
      <section className="homepage--services">
        <img
          src={Tentacles}
          alt="tentacles-homepage-services"
          className="tentacles"
        />
        <div className="container">
          <h1 className="text-center homepage--services--header">
            Our <span className="colour--red">solutions</span> put data
            <br /> at the heart of your business
          </h1>

          <hr className="dk--divider" />

          <div className="row pt-5">
            <div className="col-12 col-sm-6 col-lg-3">
              <Fade duration={2000} delay={200}>
                <ServiceCard
                  icon={DataCollectionIcon}
                  heading="Data Collection"
                  description="Robust and accurate Data Collection is the first step in creating a Data-Driven Business."
                  url="services/data-collection"
                />
              </Fade>
            </div>
            <div className="col-12 col-sm-6 col-lg-3">
              <Fade duration={2000} delay={400}>
                <ServiceCard
                  icon={DataManagementIcon}
                  heading="Data Management"
                  description="Our team are able manage to your data to ensure that it is secure, well-structured and cost-effective."
                  url="services/data-management"
                />
              </Fade>
            </div>
            <div className="col-12 col-sm-6 col-lg-3">
              <Fade duration={2000} delay={600}>
                <ServiceCard
                  icon={DataScienceIcon}
                  heading="Data Science"
                  description="We work with traditional and cutting-edge stastistical techniques and tools to get the best insight and analysis out of your data."
                  url="services/data-science"
                />
              </Fade>
            </div>
            <div className="col-12 col-sm-6 col-lg-3">
              <Fade duration={2000} delay={800}>
                <ServiceCard
                  icon={DataPresentationIcon}
                  heading="Data Delivery"
                  description="Our Business Intelligence team can deliver reports, dashboards and campaigns that can unlock your business’s potential."
                  url="services/data-delivery"
                />
              </Fade>
            </div>
          </div>
        </div>
      </section>
    </Fade>
  );
};

export default ServicesHomepage;
