import React from "react";
import Hero from "../layouts/Hero";
import HeroBG from "../images/blog_bg.svg";

import MetaTags from "react-meta-tags";

import BlogListPreview from "../layouts/BlogListPreview";

const Blog = () => {
  return (
    <section className="blog">
      <MetaTags>
        <title>Blog - Data Kraken</title>
        <meta
          name="description"
          content="Dive into our latest news and insights"
        />
      </MetaTags>

      <Hero
        heading={
          <div>
            Dive into our latest
            <br />
            news and insights
          </div>
        }
        subheading="What’s new at Data Kraken? Take a look at the latest insights and thought-provoking discoveries from our team of analysts and experts."
        icon={HeroBG}
        icon_width="40vw"
        icon_padding="0% 3%"
      />

      <BlogListPreview />
    </section>
  );
};

export default Blog;
