import React from "react";
import family_tree from "../../images/blog/three_family_members_with_same_birthday/family_tree.png";
import Fade from "react-reveal/Fade";

const Three_family_members_with_same_birthday = () => {
  // Write blog content here
  return (
    <div>
      <Fade duration={1000}>
        <p>
          <b>
            <a
              href="https://www.thesun.co.uk/fabulous/11274545/baby-mum-grandma-same-birthday/"
              target="_blank"
              rel="noopener noreferrer"
            >
              The Sun{" "}
            </a>
          </b>
          reported a story last year of a baby, mother, and grandmother who all
          shared the same birthday, the 8th Feb. Isn’t that lovely! Furthermore,
          the headline suggests they beat odds of 50 million to one to achieve
          this feat. Hmm, sounds fishy to me - let's do some investigation.
        </p>

        <h3>Some basic math(s)</h3>
        <p>
          We all know there’s 365 days in a year, or more precisely 365 and a
          quarter. We’re pretty sure The Sun wouldn’t be anything less than
          accurate, so let’s try and see how they’ve got their 50 million to 1
          figure. Lets assume they multiplied 365.25 three times.
        </p>

        <div className="alert-kraken">
          365.25 days x 365.25 days x 365.25 days = 48.72M
        </div>

        <p>
          Well, that is pretty close. 48.72M to 1 just isn’t as catchy - we’ll
          grant them some artistic license.
        </p>
        <p>
          Indeed, according to the article a William Hill representative agreed,
          stating:
        </p>

        <h5 className="py-4">
          "We would put the odds in the region of 50 million to one for three
          generations of the same family sharing a birthday."
        </h5>

        <h3>Is it that remarkable?</h3>
        <p>
          The problem with this calculation is that it makes a large assumption.
          Nobody specified which day the grandmother was to be born on, so the
          chances of her being born on her birthday are a rather less exciting 1
          to 1, or 100%. Therefore the calculation becomes:
        </p>

        <div className="alert-kraken">
          1 x 365.25 days x 365.25 days = 133,407
        </div>

        <p>This produces a rather less exciting figure of 133K to 1.</p>
        <h3>Hey, that’s still pretty high odds</h3>
        <p>
          The story does show an image of the granddaughter Evie with her SISTER
          Georgia. There’s no mention of Georgia having the same birthday as the
          mum, so presumably she hasn’t. So it’s not just a single grandmother,
          mother, granddaughter situation? How does that impact the odds?
        </p>
        <p>
          The more children in the family, the more likely this is to happen.
          Let’s look at an example:
        </p>

        <img src={family_tree} className="w-100" alt="family-tree"></img>

        <p>
          Imagine the grandmother had five children, who all went on to have
          five children each. How would that affect the odds?
        </p>

        <p>
          The odds of at least one of the five children being born on her
          birthday would drop to:
        </p>

        <div className="alert-kraken">356.25 / 5 = 73</div>

        <p>
          And the odds of at least one of the twenty-five grandchildren being
          born on her birthday would drop to:
        </p>

        <div className="alert-kraken">365.25 / 25 = 14.61</div>

        <p>Making the new calculation:</p>

        <div className="alert-kraken">73 x 14.61 = 1066</div>

        <p>
          That’s just over a thousand to one. But without knowing how many
          children are in the family, it’s impossible to work out what the real
          odds are. It’s certainly much lower than the 50 million to 1 nonsense
          in the headline.
        </p>

        <h3>Conclusion</h3>

        <p>
          In this non-story, the odds have been badly calculated and don’t
          resemble the real odds of such an event happening. Realistically, the
          chances of a grandchild being born the same day as her mother and
          grandmother would probably be less than 365. The following are
          factors:
          <ul>
            <li>Number of children in the family</li>
            <li>
              Likelihood of mother attempting to reach the "feat" once she's got
              same birthday as her mother.
            </li>
            <li>
              Family events 9 months prior to 8th February than may encourage
              sexual activity.
            </li>
          </ul>
        </p>
        <p>
          Always take such sensational stories with a pinch of salt. I was
          pretty excited to read about the 50 million to 1 odds, but have been
          left feeling deflated. The Sun has let me down, what a surprise!
        </p>
      </Fade>
    </div>
  );
};

export default Three_family_members_with_same_birthday;
