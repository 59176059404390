import React, { useState, useEffect } from "react";
import logo from "../images/dk_logo_primary.svg";

const NavBar = () => {
  const [about, setAbout] = useState(false);
  const [services, setServices] = useState(false);
  const [blog, setBlog] = useState(false);
  const [work, setWork] = useState(false);

  useEffect(() => {
    const currentPath = window.location.pathname.split("/")[1];

    if (currentPath === "about") {
      setAbout(true);
    } else if (currentPath === "services") {
      setServices(true);
    } else if (currentPath === "blog") {
      setBlog(true);
    } else if (currentPath === "work") {
      setWork(true);
    }
  }, []);

  return (
    <section className="nav">
      <div className="container">
        <nav className="navbar navbar-expand-lg">
          <div className="justify-content-start">
            <a href="/">
              <img src={logo} className="nav__logo" alt="dk_logo" />
            </a>
          </div>

          <button
            className="navbar-toggler collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo01"
            aria-controls="navbarTogglerDemo01"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <div id="nav-icon">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </button>

          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarTogglerDemo01"
          >
            <ul className="pt-4">
              <li className="nav__list">
                <a
                  href="/about"
                  className={`nav__link ${about ? "active" : ""}`}
                >
                  About
                </a>
              </li>
              <li className="nav__list">
                <a
                  href="/services"
                  className={`nav__link ${services ? "active" : ""}`}
                >
                  Services
                </a>
              </li>

              <li className="nav__list">
                <a href="/blog" className={`nav__link ${blog ? "active" : ""}`}>
                  Blog
                </a>
              </li>
              <li className="nav__list">
                <a href="/work" className={`nav__link ${work ? "active" : ""}`}>
                  Work
                </a>
              </li>
              <li className="nav__list  nav__cta">
                <a href="/contact" className="btn btn--red nav__link">
                  Get in touch
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </section>
  );
};

export default NavBar;
