import React from "react";
import VisionIcon from "../images/team-photos-gaming.jpg";
import Fade from "react-reveal/Fade";

const AboutVision = () => {
  return (
    <section className="about--vision">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6 ">
            <img src={VisionIcon} className="w-100" alt="values"></img>
          </div>
          <Fade duration={1000} distance="10rem" left>
            <div className="col-12 col-lg-6 about--vision__text order-first order-lg-last">
              <h1>Our Values</h1>
              <p className="py-3">
                Data Kraken is a fun and dynamic business which is constantly
                pushing the boundaries in analytics to develop new and
                innovative solutions for our clients. We are results driven and believe in
                continuous improvement for ourselves as well as our clients.
                <br />
                <br />
                We strive to go above and beyond and we value
                creating personal relationships with our clients. Being authentic and
                developing strong connections is key to get the very best
                solutions for our clients.
              </p>
            </div>
          </Fade>
        </div>
      </div>
    </section>
  );
};

export default AboutVision;
