import React from "react";
import Hero from "../layouts/Hero";
import HeroBG from "../images/homepage_hero2.svg";
import HeroBGMobile from "../images/homepage_hero_mobile.svg";
import HomepageSquares from "../images/homepage_squares.svg";

import HomepageServices from "../layouts/HomepageServices";
import HomepageWork from "../layouts/HomepageWork";
import HomepageTeam from "../layouts/HomepageTeam";
import HomepageBlog from "../layouts/HomepageBlog";
import Testimonials from "../layouts/Testimonials";
import HomepageContact from "../layouts/HomepageContact";
import MetaTags from "react-meta-tags";

import Fade from "react-reveal/Fade";

import Clients from "../images/clients.png";

const Homepage = () => {
  return (
    <section className="homepage">
      <MetaTags>
        <title>
          Data Kraken - Improve your business's decision-making using logic,
          reason and data.
        </title>
        <meta
          name="description"
          content="We come up with the solutions that best utilise your data to help you make better informed decisions."
        />
        <meta property="og:title" content="Data Kraken" />
        <meta
          property="og:description"
          content="Data Kraken - Improve your business's decision-making using logic,
          reason and data."
        />
        <meta
          property="og:image"
          content="https://datakraken.net/static/media/dk_logo_primary.5c1ebd17.svg"
        />
      </MetaTags>

      <Hero
        heading={
          <h1>
            Improve your business's <br />
            decision-making using
            <br /> <span className="colour--red">logic</span>,
            <span className="colour--red"> reason</span> and{" "}
            <span className="colour--red">data</span>
          </h1>
        }
        subheading="We can help find the solutions that best utilise your data, enabling you to make better informed decisions."
        cta="How we can help"
        icon={HeroBG}
        icon_mobile={HeroBGMobile}
        particles={HomepageSquares}
      />

      <section className="homepage--clients">
        <Fade duration={2000}>
          <div className="container px-5 pb-5 text-center">
            <img src={Clients} alt="clients" className="w-100" />
          </div>
        </Fade>
      </section>

      <HomepageServices />
      <HomepageWork />
      <HomepageTeam />
      <HomepageBlog />
      <Testimonials />
      <HomepageContact />
    </section>
  );
};

export default Homepage;
