import React from "react";

const WorkPostPreview = ({ Title, Subtitle, Banner, URL }) => {
  return (
    <div className="work--preview">
      <a href={URL}>
        <div className="work--preview--banner">
          <img src={Banner} className="w-100" alt="preview"></img>

          <div className="work--preview--text">
            <h3 className="work--title">{Title}</h3>
            <h6 className="work--subtitle">{Subtitle}</h6>
          </div>
        </div>
      </a>
    </div>
  );
};

export default WorkPostPreview;
