import React from "react";
import TeamIcon from "../images/homepage_team.svg";
import TeamIconMobile from "../images/homepage_team_mobile.svg";

import Fade from "react-reveal/Fade";

const HomepageTeam = () => {
  return (
    <section className="homepage--team">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6 homepage--team__text">
            <Fade duration={2000}>
              <h1>
                A passionate team
                <br /> that cares
              </h1>
            </Fade>
            <Fade duration={2000}>
              <p className="py-3">
                The unique group of driven like-minded individuals at Data
                Kraken are bonded by a passion to develop the best data
                solutions for each customer, whilst delivering a friendly, yet
                professional service.
              </p>
            </Fade>
            <Fade duration={2000}>
              <a href="/about" className="btn btn--white">
                Meet the team
              </a>
            </Fade>
          </div>
        </div>
      </div>

      <Fade duration={2000}>
        <img
          src={TeamIcon}
          className="homepage--team__icon"
          alt="work-icon"
        ></img>
        <img
          src={TeamIconMobile}
          className="homepage--team__icon--mobile"
          alt="work-icon"
        ></img>
      </Fade>
    </section>
  );
};

export default HomepageTeam;
