import React from "react";
import Fade from "react-reveal/Fade";

const BlogPostHeader = ({ BlogHeader }) => {
  return (
    <section className="blog--post--header">
      <Fade duration={2000}>
        <div className="blog--post--header__heading ">
          <h1>{BlogHeader.title}</h1>
        </div>
      </Fade>
      <Fade duration={2000} delay={200}>
        <div className="blog--post--header__subheading">
          <h6>By {BlogHeader.author}</h6>
          <p>Posted on {BlogHeader.date}</p>
        </div>
      </Fade>

      <Fade duration={2000} delay={400}>
        <img
          src={BlogHeader.banner}
          className="blog--post--header__img"
          alt="banner"
        ></img>
      </Fade>
    </section>
  );
};

export default BlogPostHeader;
