import React from "react";
import TentaclesServices from "../images/tentacles-services.svg";
import Fade from "react-reveal/Fade";

const FitWhyNow = () => {
  return (
    <section className="why--now">
      <div className="container">
        <div className="row pt-5">
          <div className="col-12 col-lg-8">
            <Fade duration={2000} delay={0}>
              <h2 className="why--now--heading">Why Now?</h2>
              <div className="row">
                <div className="col-12 col-lg-6">
                  <div className="row why--now--text">
                    <h5>Outdated legacy system</h5>
                    <p>
                      An outdated legacy system can cripple a business in ways
                      you may not be aware until it is too late. We can migrate
                      your data to a new system or audit and fix problems in
                      your existing system.
                    </p>
                  </div>

                  <div className="row pt-5 why--now--text">
                    <h5>Increase efficiency</h5>
                    <p>
                      Old processes may be slowing you down. We’ll streamline
                      your existing processes through automation, using the
                      latest technology to free you up to focus on other areas
                      of your business.
                    </p>
                  </div>
                </div>

                <div className="col-12 col-lg-6">
                  <div className="why--now--marketing">
                    <h5>Growing business</h5>
                    <p>
                      Being unaware of key metrics now can cause problems down
                      the line. We’ll help address these issues giving you
                      confidence for the ongoing growth of your company.
                    </p>
                  </div>
                </div>
              </div>
            </Fade>
          </div>

          <div className="col-0 col-lg-4 d-flex">
            <Fade duration={2000} delay={200}>
              <img
                src={TentaclesServices}
                alt=""
                className="tentacles--services"
              />
            </Fade>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FitWhyNow;
