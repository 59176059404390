import React from "react";

const WorkPostSummaryPoints = ({ Client, Industry, Service, Location }) => {
  return (
    <section className="work--post--summary--points">
      <h5>Client</h5>
      <h6>{Client}</h6>
      <h5>Industry</h5>
      <h6>{Industry}</h6>
      <h5>Service</h5>
      <h6>{Service}</h6>
      <h5>Location</h5>
      <h6>{Location}</h6>
    </section>
  );
};

export default WorkPostSummaryPoints;
