import React from "react";
import Fade from "react-reveal/Fade";
import Tentacles from "../images/tentacles-work-post.svg";

const WorkHero = ({ Overview, Logo, Banner, logo_width, logo_padding }) => {
  return (
    <section className="work--hero">
      <div className="container">
        <div className="row">
          <div className="col-lg-9 col-12">
            <div className="work--hero__heading">
              <Fade duration={1500}>
                <h1>
                  Case Study
                  <img src={Logo} alt="logo" className="work--hero__logo" />
                </h1>
              </Fade>
            </div>
            <Fade delay={200} duration={1500}>
              <div className="work--hero__overview">
                <h6>{Overview}</h6>
              </div>
            </Fade>
          </div>
        </div>

        <Fade duration={2000}>
          <div className="row">
            <div className="col-lg-8 col-12">
              <img src={Banner} alt="banner" className="work--hero__banner" />
            </div>
          </div>
        </Fade>

        <Fade duration={2000}>
          <img src={Tentacles} alt="tentacles-work" className="tentacles" />
        </Fade>
      </div>
    </section>
  );
};

export default WorkHero;
